import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../redux/hooks';
import { useTheme } from '../themeContext';

// -- Redux 

// -- Components 

import Notification from '../components/general/Notification';
import NavBar from '../components/general/navBar/NavBar';
import DoctorSettings from '../components/doctor/navigation/navBar/Settings'; 
import Container from '../components/consultationNote/Container';

const ConsultationNote: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const colors = useAppSelector(state => state.theme.colors);

  const { isLightMode, toggleTheme } = useTheme();

  const navigate = useNavigate();
  const { t } = useTranslation();

  // -- 

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: colors?.highlightedText,
        backgroundColor: colors?.interface
      }}
    >
        <NavBar>
            <DoctorSettings /> 
        </NavBar>

        <Container />

        <Notification />

    </Box>
  );
};

export default ConsultationNote;