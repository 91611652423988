import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import FoldersList from './FoldersList';
import FileViewerOptions from '../actions/fileViewer/FileViewerOptions';
import NoteViewerOptions from '../actions/noteViewer/NoteViewerOptions';
import AttachedFileViewerOptions from '../actions/attachedFileViewer/AttachedFileViewerOptions';
import ImageViewerOptions from '../actions/consultationImageViewer/ImageViewerOptions';

const NavigationColumn: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const colors = useAppSelector(state => state.theme.colors); 
    const mode = useAppSelector(state => state.fileExplorer.mode); 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: isSmallScreen ? '60px' : '15%',
            height: '95%', 
            borderRadius: 10,
            backgroundColor: colors?.container,
            overflowY: 'auto',
            overflowX: 'hidden',
            transition: 'width 0.3s',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}>

            { ((mode !== 'viewFile') && (mode !== 'viewNote') && (mode !== 'viewAttachedFile') && (mode !== 'viewConsultationImage')) && <FoldersList />}

            { mode === 'viewFile' && <FileViewerOptions />}

            { mode === 'viewNote' && <NoteViewerOptions />}

            { mode === 'viewAttachedFile' && <AttachedFileViewerOptions />}

            { mode === 'viewConsultationImage' && <ImageViewerOptions />}
            
        </Box>

    );

};

export default NavigationColumn;