import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import { addTextField, deleteTextField, setIsAttributesDropdownOpened, setIsCreatingTextField, setSection, setSelectedTextField, setSelectedTextFieldId, setTextFields, updateTextFields } from '../../../../redux/features/template/template';
import { deleteTemplateTextField, updateTemplateTextField } from '../../../../routes/doctor/templates';
import TitleIcon from '@mui/icons-material/Title';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import WcIcon from '@mui/icons-material/Wc';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddHomeIcon from '@mui/icons-material/AddHome';
import EmailIcon from '@mui/icons-material/Email';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TollIcon from '@mui/icons-material/Toll';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { setTemplatesSection } from '../../../../redux/features/doctor/Dashboard/templates/templates';


const OptionsBar: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const selectedTextField = useAppSelector(state => state.template.selectedTextField); 
    const selectedTextFieldId = useAppSelector(state => state.template.selectedTextFieldId); 
    const selectedTextFieldIndex = useAppSelector(state => state.template.selectedTextFieldIndex); 
    const attribute = useAppSelector(state => state.template.selectedTextField.attribute); 
    const isAttributesDropdownOpened = useAppSelector(state => state.template.isAttributesDropdownOpened); 
    const fontSize = useAppSelector(state => state.template.fontSize); 
    const textColor = useAppSelector(state => state.template.textColor); 
    const textAlign = useAppSelector(state => state.template.textAlign) as any; 
    const textDecoration = useAppSelector(state => state.template.textDecoration); 
    const fontWeight = useAppSelector(state => state.template.fontWeight); 
    const fontStyle = useAppSelector(state => state.template.fontStyle); 
    const textFields = useAppSelector(state => state.template.textFields); 
    
    // -- 

    const handleDeletion = async () => { 

        const res = await deleteTemplateTextField(selectedTextFieldId) as any; 

        if (res?.data.status === 200) { 

            dispatch(deleteTextField({ textField: selectedTextField, index: selectedTextFieldIndex })); 

            dispatch(setSelectedTextField(null)); 

        }; 

    }; 

    // -- 

    const handleAttributes = () => { 

        if (isAttributesDropdownOpened) { 

            dispatch(setIsAttributesDropdownOpened(false)); 

        } else { 

            dispatch(setSection('forms'));
            dispatch(setIsAttributesDropdownOpened(true)); 

        }; 

    };

    // -- 

    const removeAttribute = async () => { 

        const content = { 
            textFieldId: selectedTextFieldId,
            attribute: 'none', 

        }; 

        const res = await updateTemplateTextField(content) as any; 

        if (res.data.status == 200) {

            dispatch(updateTextFields({ 
                selectedTextFieldId: selectedTextFieldId,
                updatedTextField: res.data.templateTextField,
                index: selectedTextFieldIndex 
            })); 

            dispatch(setSelectedTextField({ textField: res.data.templateTextField, index: selectedTextFieldIndex }));

        }; 
        
    };

    // -- 

    return (

      <Box 
        sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            position: 'absolute', 
            left: 0,
            bottom: -50, 
            width: 'auto', 
            height: 30, 
            borderRadius: 1, 
            backgroundColor: 'rgba(60,109,109)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)',  
            zIndex: 4, 
        }}>

        { attribute ?  

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    marginLeft: 1, 
                    paddingLeft: 1, 
                    paddingRight: 1, 
                    borderRadius: 2, 
                    height: 22, 
                    backgroundColor: 'rgba(232,234,246, 0.6)'
                }}
            >

                { attribute === 'patientId' && <TollIcon sx={{ fontSize: 18, color: colors?.patientId }}/> }
                { attribute === 'firstName' && <AccountCircleIcon sx={{ fontSize: 18, color: colors?.firstName }}/> }
                { attribute === 'lastName' && <AccountBoxIcon sx={{ fontSize: 18, color: colors?.lastName}}/>}
                { attribute === 'sex' && <WcIcon sx={{ fontSize: 18, color: colors?.gender }}/>}
                { attribute === 'dateOfBirth' && <EventNoteIcon sx={{ fontSize: 18, color: colors?.dateOfBirth }}/>}
                { attribute === 'healthCardNumber' && <AccountCircleIcon sx={{ fontSize: 18, color: colors?.healthCardNumber }}/> }
                { attribute === 'phone' && <LocalPhoneIcon sx={{ fontSize: 18, color: colors?.phone }}/>}
                { attribute === 'address' && <AddHomeIcon sx={{ fontSize: 18, color: colors?.address }}/>}
                { attribute === 'email' && <EmailIcon sx={{ fontSize: 18, color: colors?.email }}/>}
                { attribute === 'emergencyContactName' && <AccountCircleIcon sx={{ fontSize: 18, color: colors?.emergencyContactName }}/>}
                { attribute === 'emergencyContactRelation' && <AccountBoxIcon sx={{ fontSize: 18, color: colors?.emergencyContactRelation }}/>}
                { attribute === 'emergencyContactPhone' && <AddHomeIcon sx={{ fontSize: 18, color: colors?.emergencyContactPhone }}/>}

                <ClearIcon 
                    onClick={removeAttribute}
                    sx={{ 
                        color: colors?.text, 
                        fontSize: 20, 
                        marginLeft: 0.5, 
                        cursor: 'pointer', 
                        '&:hover': { color: colors?.deleteIcon}
                    }} /> 

            </Box>: 
            
            <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                marginLeft: 1, 
                marginRight: 1, 
            }}
            >

                <BookmarkAddIcon 
                    onClick={handleAttributes}
                    sx={{ 
                        cursor: 'pointer',
                        color: 'rgb(255, 255, 255)',
                        '&:hover':  { color: 'rgb(80, 255, 255)' }
                    }} />
                
            </Box>}

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    marginLeft: 1, 
                    marginRight: 1, 
                }}
            >
                <RemoveCircleIcon 
                    onClick={handleDeletion}
                    sx={{ 
                        cursor: 'pointer',
                        color: 'rgb(255, 255, 255)',
                        '&:hover':  { color: 'rgb(80, 255, 255)' }
                    }} />
                
            </Box>


      </Box>

  );

};

export default OptionsBar;