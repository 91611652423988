import React, { useState, useEffect, useRef } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import Actions from './Actions';
import { serveConsultationNote } from '../../../../../routes/doctor/consultation';

const NoteViewer: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const { t } = useTranslation(); 

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');
    const leftOffset = isSmallScreen ? '60px' : '5vw';

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const colors = useAppSelector(state => state.theme.colors);

    const selectedConsultationNote = useAppSelector(state => state.consultation.selectedConsultationNote) as any; 

    const [fileUrl, setFileUrl] = useState(''); 

    // -- 

    const handleConsulationNote = async () => { 
        
        const res = await serveConsultationNote(selectedConsultationNote._id) as any; 

        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });

        setFileUrl(URL.createObjectURL(fileBlob));  

    }; 

    // -- 

    useEffect(() => { 

        if (selectedConsultationNote) { 

            handleConsulationNote(); 

        }; 

    },[selectedConsultationNote]); 

  // --

  return (

    <Box 
        style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            width: '100%',
            height: '100%', 
        }}>

            <Actions /> 

            <Box 
            
                style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    width: '80%',
                    height: '95%', 
                    borderRadius: 10,
                    backgroundColor: colors?.container,
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    
            }}>

                { fileUrl && 
                    <iframe
                        ref={iframeRef}
                        src={fileUrl}
                        style={{ 
                            width: '100%', 
                            height: '100%',
                        }}
                        title="PDF Viewer"
                /> }

            </Box>

        </Box>
  );
};

export default NoteViewer;