import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Logo from './items/Logo';
import Account from './items/Account';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Header: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          justifyContent: 'space-evenly', // space-between 
          width: '95vw',
          height: '7.5vh',
          position: 'absolute',
          top: 0,
          overflow: 'hidden',  
      }}>

        {/* <InfoOutlinedIcon 
          sx={{ width: 30, height: 30, color: colors?.darkBlue }} 
        />  */}

        <Logo />

        {/* <Account />  */}

      </Box>

  );

};

export default Header;