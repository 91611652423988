import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
// Components

import FolderIcon from '@mui/icons-material/Folder';

// Icons 

import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { setCategory } from '../../../../../../redux/features/doctor/Dashboard/consultation/consultation';


type Props = {
    category: string;
    numberOfItems: number | 0 
};


const CategoryRow: React.FC<Props> = ({ category, numberOfItems }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const currentCategory = useAppSelector((state) => state.consultation.category); 


    // -- 

    const handleCategory = () => { 

        dispatch(setCategory(category)); 

    }; 

    return (

        <Box 
            onClick={handleCategory}
            sx={{
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-between', 
            width: '100%',
            height: 40,
            overflow: 'hidden',
            color: colors?.text,
            backgroundColor: category == currentCategory ? colors?.sectionHover : 'transparent',
            '&:hover': { backgroundColor: category == currentCategory ? colors?.selectedSectionHover : colors?.sectionHover }
        }}>

            <Box
                sx={{
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                }}
            >

                { category === 'actions' && <AddCircleOutlineIcon sx={{ color: colors?.green, fontSize: 25, marginLeft: 2 }} />}
                { category === 'notes' && <SpeakerNotesIcon sx={{ color: colors?.purple, fontSize: 25, marginLeft: 2 }} />}
                { category === 'files' && <DescriptionIcon sx={{ color: colors?.file, fontSize: 25, marginLeft: 2 }} />}
                { category === 'images' && <CropOriginalIcon sx={{ color: colors?.darkPurple, fontSize: 25, marginLeft: 2 }} />}

                <Box sx={{ width: '50%', fontSize: 14, marginLeft: 2, overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: 2 }}>{t(category)}</Box>


            </Box>


            { numberOfItems > 0 && 
            
                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly', 
                        width: 20, 
                        height: 20, 
                        marginRight: 2, 
                        borderRadius: '50%',
                        borderWidth: 1, 
                        borderStyle: 'solid', 
                        boderColor: 'blue',
                        fontSize: 12
                    }}
                >{numberOfItems}
                </Box>}

        </Box>

    );

};

export default CategoryRow;