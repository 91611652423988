import { AcUnitTwoTone, FlashAutoRounded } from '@mui/icons-material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import attributesList from '../../../../../components/consultationNote/document/attributesList';

interface ConsultationNoteState {
    consultationNoteId: string; 
    isSavingEnabled: boolean; 
    mode: string; 
    securityPin: string; 
    isSecurityPopupOpened: boolean;
    attributesQueryList: any[]; 
    selectedAttribute: string; 
    searchQuery: string; 
    isAttributesDropdownOpened: boolean; 
    hightlightedAttribute: string; 
}; 

const initialState: ConsultationNoteState = {
    consultationNoteId: '',
    isSavingEnabled: false, 
    mode: '',
    securityPin: '', 
    isSecurityPopupOpened: false, 
    attributesQueryList: attributesList,
    selectedAttribute: '', 
    searchQuery: '', 
    isAttributesDropdownOpened: false, 
    hightlightedAttribute: '', 
};

const consultationNoteSlice = createSlice({
  name: 'consultationNote',
  initialState,
  reducers: {
    setConsultationNoteId: (state, action: PayloadAction<string>) => {
      state.consultationNoteId = action.payload;
    },
    setIsSavingEnabled: (state, action: PayloadAction<boolean>) => {
      state.isSavingEnabled = action.payload;
    },
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setSecurityPin: (state, action: PayloadAction<string>) => {
      state.securityPin = action.payload;
    },
    setIsSecurityPopupOpened: (state, action: PayloadAction<boolean>) => {
      state.isSecurityPopupOpened = action.payload;
    },
    setAttributesQueryList: (state, action: PayloadAction<any>) => {
      state.attributesQueryList = action.payload;
    },
    setSelectedAttribute: (state, action: PayloadAction<string>) => {
      state.selectedAttribute = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setIsAttributesDropdownOpened: (state, action: PayloadAction<boolean>) => {
      state.isAttributesDropdownOpened = action.payload;
    },
    setHightlightedAttribute: (state, action: PayloadAction<string>) => {
      state.hightlightedAttribute = action.payload;
    },
    resetConsultationNote: (state) => { 
        
      state.consultationNoteId = '';
      state.isSavingEnabled = false; 
      state.mode = ''; 
      state.securityPin = ''; 
      state.isSecurityPopupOpened = false; 
      state.attributesQueryList = attributesList; 
      state.selectedAttribute = ''; 
      state.searchQuery = ''; 
      state.isAttributesDropdownOpened = false; 
      state.hightlightedAttribute = ''; 

    } 
  },
});

export const { 
  setConsultationNoteId, 
  setIsSavingEnabled, 
  setMode, 
  setSecurityPin, 
  setAttributesQueryList, 
  setIsSecurityPopupOpened, 
  setSearchQuery, 
  setSelectedAttribute,
  setIsAttributesDropdownOpened, 
  setHightlightedAttribute, 
  resetConsultationNote } = consultationNoteSlice.actions;

export default consultationNoteSlice.reducer;