import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Button from '@mui/material/Button';
import { resetFileExplorer, selectFile, setMode } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setDashboardSection } from '../../../../../redux/features/general/navigation';
import { serveFile } from '../../../../../routes/doctor/files';
import { attachFileToConsultation } from '../../../../../routes/doctor/consultation';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import { setIsNewFileAttached } from '../../../../../redux/features/doctor/Dashboard/consultation/consultation';

const AttachFileToConsultation: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder); 
    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 
    const consultationId = useAppSelector(state => state.consultation.consultationId); 

    const [path, setPath] = useState('Select a file to attach to your consultation.');

    // -- 

    const leaveAction = () => { 

        dispatch(setMode('')); 
        dispatch(selectFile(null)); 

        dispatch(setDashboardSection('consultation')); 

    }; 

    // -- 

    const attachFile = async () => { 

        if (selectedFile) { 

            const res = await serveFile(selectedFile._id) as any; 

            if (res) { 

                const formData = new FormData() as any; 

                const newFile = new File([res.data], selectedFile.title, { type: "application/pdf" });
    
                formData.append('file', newFile); 
                formData.append('consultationId', consultationId); 
        
                const response = await attachFileToConsultation(formData) as any;  

                if (response.status === 200) { 

                    dispatch(setIsNewFileAttached(true)); 

                    dispatch(setDashboardSection('consultation')); 

                    dispatch(resetFileExplorer()); 

                }; 

            }; 
    
        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (selectedFile) { 

            setPath(`${selectedFile?.path}/${selectedFile?.title}`); 

        }; 

    },[selectedFile]); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 60, 
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '50%',
                    height: 40, 
                    marginLeft: '2%', 
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: colors?.fileExplorerFooter
                }}
            >
                <InsertDriveFileIcon sx={{ color: colors?.driveIcon, fontSize: 15, marginLeft: 2 }} />

                <Box 
                    sx={{
                        fontSize: 12, 
                        marginLeft: 3
                    }}>
                    {path}
                </Box>

            </Box>

            <Box style={{ 
                display: 'flex', 
                flexDirection: 'row-reverse',
                alignItems: 'center',  
                width: '50%',
            }}>

                <Button 
                    onClick={() => leaveAction()} 
                    sx={{ 
                        marginLeft: 1,
                        marginRight: 3,
                        color: colors?.closeButtonText, 
                        backgroundColor: colors?.closeButtonBackground,
                        borderColor: colors?.closeButtonBackground,
                        '&:hover': 
                        { 
                            backgroundColor: colors?.closeButtonBackground,
                            color: colors?.closeButtonText,
                            borderColor: colors?.closeButtonBackground
                        }
                    }}
                    variant="contained">{t('cancel')}
                </Button>


                { selectedFile ? 
                <Button onClick={() => attachFile()} sx={{ marginLeft: 5 }} variant="contained">{t('attachFile')}</Button>: 
                <Button 
                disabled={true}
                sx={{ 
                    backgroundColor: 'transparent',
                    borderColor: 'lightblue',
                    marginLeft: 5
                }}
                variant="outlined">{t('attachFile')}</Button>}

                </Box>

        </Box>

    );

};

export default AttachFileToConsultation;