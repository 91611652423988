import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setIsAutoCompleteProcessing, setIsDocumentAutoCompleted, setSelectedPatient, setSelectedTextField, setUpdatePdf, updateTextFields } from '../../../../../../redux/features/template/template';

// Components 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Logo from '../../../../../general/navBar/Logo';
import EventLimiter from '../../../../../../util/events/EventLimiter';
import { getTemplateTextFieldAutoComplete } from '../../../../../../routes/doctor/templates';
import { setNotificationMessage } from '../../../../../../redux/features/general/notification';

const AutoCompleteInfo: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedPatient = useAppSelector(state => state.template.selectedPatient);  
    const textFields = useAppSelector(state => state.template.textFields); 
    const selectedTextFieldIndex = useAppSelector(state => state.template.selectedTextFieldIndex);  

    // -- 

    const handleUnSelection = () => { 

        dispatch(setSelectedPatient(null)); 

    }; 

    // --

    const handleAutoComplete = async () => { 

        dispatch(setIsAutoCompleteProcessing(true)); 

        textFields?.forEach(async (textField, index) => { 
    
            try {
    
                await EventLimiter.schedule(() => getAutoCompleteData(textField._id, selectedPatient.patientId, index));
        
            } catch (err) {
                console.log(err);
            };
    
        });

    }; 

    // -- 

    const getAutoCompleteData = async (textFieldId: string, patientId: string, index: number) => { 

        dispatch(setSelectedTextField(null)); 

        const res = await getTemplateTextFieldAutoComplete(textFieldId, patientId) as any; 

        if (res.data.status === 200) { 

            dispatch(updateTextFields({ 
                selectedTextFieldId: textFieldId,
                updatedTextField: res.data.templateTextField,
                index: index
            })); 

        }; 

        if ((index === (textFields.length - 1))) { 

            dispatch(setIsDocumentAutoCompleted(true)); 
            dispatch(setIsAutoCompleteProcessing(false)); 
            dispatch(setNotificationMessage('Document Successfully Processed.'));

        }; 

    }; 

    // -- 

    return (

        <Box 
            sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            color: colors?.text,
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 3, 
                }}
            >
                <KeyboardArrowLeftIcon 
                    onClick={handleUnSelection}
                    sx={{ 
                        width: 30,
                        height: 30,
                        borderRadius: '50%', 
                        fontSize: 25, 
                        marginLeft: 2,
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: colors?.autoCompleteQuitHover}
                    }}
                /> 

            </Box>

            <AccountCircleIcon 
                sx={{ 
                    color: 'rgb(80,199,199)', 
                    fontSize: 40, 
                    width: 50, 
                    height: 50, 
                    marginBottom: 2, 
                    borderRadius: '50%', 
                    backgroundColor: colors?.autoCompleteAccountBackground
                }} />

            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    width: '80%',
                    fontSize: 14, 
                    marginBottom: 2, 
                    textAlign: 'center',
                    color: colors?.text
                }}>
                {selectedPatient?.fullName}
            </Box>

            <Box
                onClick={handleAutoComplete}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly',
                    width: '80%', 
                    borderRadius: 1, 
                    marginBottom: 2, 
                    height: 30,
                    cursor: 'pointer', 
                    color: 'white',
                    backgroundColor: colors?.autoCompleteSubmitButton,
                    '&:hover': { backgroundColor: colors?.autoCompleteSubmitButtonHover}
                }}
            > 
                <Box>{t('continue')}</Box>       
            </Box>

            <Box
                sx={{
                    width: '80%', 
                    height: 'auto',
                    textAlign: 'center', 
                    fontSize: 12, 
                    marginBottom: 2, 
                }}
            >{t('autoCompleteInfo')}
            </Box>
            
        </Box>
  );
};

export default AutoCompleteInfo;