import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface InitialState {
    selectedImage: any,
    blobFileUrl: any,
    fileExtension: any,  
    fileName: any, 
    isImageViewerOpened: boolean, 
}

const initialState: InitialState = {
  selectedImage: null,
  blobFileUrl: null,
  fileExtension: null, 
  fileName: null, 
  isImageViewerOpened: false, 
};

export const imageViewerSlice = createSlice({
  name: 'imageViewer',
  initialState,
    reducers: {
    setSelectedImage: (state, action: PayloadAction<any>) => {
      state.selectedImage = action.payload 
    },
    setBlobFileUrl: (state, action: PayloadAction<any>) => {
      state.blobFileUrl = action.payload 
    },
    setFileExtension: (state, action: PayloadAction<any>) => {
      state.fileExtension = action.payload 
    },
    setFileName: (state, action: PayloadAction<any>) => {
      state.fileName = action.payload 
    },
    setIsImageViewerOpened: (state, action: PayloadAction<boolean>) => {
      state.isImageViewerOpened = action.payload 
    },
    resetImageViewer: (state) => { 

      state.selectedImage = null; 
      state.blobFileUrl = null; 
      state.fileExtension = null; 
      state.fileName = null; 
      state.isImageViewerOpened = false; 

    } 
  },
});

export const {
  setSelectedImage, 
  setBlobFileUrl, 
  setFileExtension, 
  setFileName, 
  setIsImageViewerOpened, 
  resetImageViewer
} = imageViewerSlice.actions;

export default imageViewerSlice.reducer;