import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

import ActionBox from './ActionBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddToDriveOutlinedIcon from '@mui/icons-material/AddToDriveOutlined';
import { useTranslation } from 'react-i18next';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';


const Actions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

        <Box             
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                width: '100%',
                height: 'auto', 
                paddingLeft: '2%'
            }}>

            <ActionBox 
                title={'Note'} 
                section={'consultationNote'}
                data={'Add a new note to your consultation.'} 
                hoverColor={colors?.consultationNoteHover}
                backgroundColor={colors?.consultationNote}>
                <EventNoteIcon sx={{ color: colors?.purple }} />
            </ActionBox>

            <ActionBox 
                title={String(t('attachFile'))} 
                section={'attachFile'}
                data={'Link an existing file to your consultation.'} 
                hoverColor={colors?.attachFileHover}
                backgroundColor={colors?.attachFile}>
                <AddToDriveOutlinedIcon sx={{ color: colors?.attachFileIcon }} />
            </ActionBox>
        
        </Box>

    );

};

export default Actions;