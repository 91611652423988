import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { setSection } from '../../../../../redux/features/doctor/Dashboard/consultation/consultation';

// Routes

// Utils 


const QuitButton: React.FC = () => {

  const dispatch = useAppDispatch(); 

    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    const quiteNoteViewer = async () => { 

        dispatch(setSection('dashboard')); 

    }; 


    // -- 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginTop: 1,
            marginBottom: 2, 
            height: '5vh',
            width: '100%',
        }}>

            <Button 
                sx={{ 
                width: '75%',
                backgroundColor: colors?.consultationQuit,
                borderColor: colors?.consultationQuit,
                color: 'white',
                marginBottom: 2,
                '&:hover': 
                { 
                    backgroundColor: colors?.consultationQuitHover,
                    color: colors?.exitButtonText,
                    borderColor: colors?.consultationQuitHover
                    }
                }}
                onClick={quiteNoteViewer}
                variant="outlined">{t('leave')}
            </Button>

        </Box>

  );

};

export default QuitButton;