import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NoteMicrophoneState {
    consultationNoteId: string; 
    mode: string; 
    securityPin: string; 
    isOptionsListOpened: boolean; 
    isSettingsListOpened: boolean; 
    language: string; 
}; 

const initialState: NoteMicrophoneState = {
    consultationNoteId: '',
    mode: '', 
    securityPin: '', 
    isOptionsListOpened: false, 
    isSettingsListOpened: false, 
    language: 'en-CA', 
};

const noteMicrophoneSlice = createSlice({
  name: 'noteMicrophone',
  initialState,
  reducers: {
    setConsultationNoteId: (state, action: PayloadAction<string>) => {
      state.consultationNoteId = action.payload;
    },
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setSecurityPin: (state, action: PayloadAction<string>) => {
      state.securityPin = action.payload;
    },
    setIsOptionsListOpened: (state, action: PayloadAction<boolean>) => {
      state.isOptionsListOpened = action.payload;
    }, 
    setIsSettingsListOpened: (state, action: PayloadAction<boolean>) => {
      state.isSettingsListOpened = action.payload;
    }, 
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    }, 
    resetConsultationNote: (state) => { 
        
      state.consultationNoteId = '';
      state.mode = ''; 
      state.securityPin = ''; 
      state.isOptionsListOpened = false; 
      state.isSettingsListOpened = false; 
      state.language = 'en-CA'; 

    } 
  },
});

export const { 
  setConsultationNoteId, 
  setMode,
  setSecurityPin,  
  setIsOptionsListOpened, 
  setIsSettingsListOpened, 
  setLanguage, 
  resetConsultationNote } = noteMicrophoneSlice.actions;

export default noteMicrophoneSlice.reducer;