import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux 

import { setFile, setIsFileUploaded } from '../../../../../../redux/features/doctor/Dashboard/documents/uploadFile';


// Icons 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { uploadImageToConsultation } from '../../../../../../routes/doctor/consultation';
import { setIsNewFileAttached, setIsNewImageUploaded } from '../../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { setClosePopup, setOpenPopup, setPopupAction } from '../../../../../../redux/features/general/popup';

const UploadImageButton: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const consultationId = useAppSelector(state => state.consultation.consultationId); 

    const imageInput = document.getElementById("consultation-image-upload") as any; 

    const [uploadedImage, setUploadedImage] = useState(null) as any; 

    // -- 

    const handleUpload = async (file: any) => { 

        const formData = new FormData() as any; 

        formData.append('consultationId', consultationId); 
        formData.append('file', file); 

        dispatch(setOpenPopup()); 
        dispatch(setPopupAction('uploadImage')); 

        const res = await uploadImageToConsultation(formData) as any;  

        dispatch(setClosePopup()); 

        if (res.status === 200) { 

            imageInput.value = null; 

            dispatch(setIsNewImageUploaded(true)); 

        }; 
    }; 

    // -- 

    const handlePictureSelection = (event: React.ChangeEvent<HTMLInputElement> | any) => {

        const files = event.target.files as any; 

        if (files.length > 0) {

          handleUpload(event.target.files[0]) as any; 
    
        }; 
    };

    // -- 

    // useEffect(() => { 

    //     if (uploadedImage) { 

    //         handleUpload(); 

    //     }; 

    // },[uploadedImage]); 

    // -- 

    return (

        <Button
            component="label"
            sx={{ 
                all: 'initial',                    
                width: 100, 
                height: 30,
                position: 'absolute', 
                cursor: 'pointer',
                '&:hover': { backgroundColor: 'transparent'},
                zIndex: 1}}
            >
                <input
                    id="consultation-image-upload"
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handlePictureSelection}
                />
        </Button>

    );

};

export default UploadImageButton;