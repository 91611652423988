import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    refreshFileExplorer: boolean,  
    searchQuery: string, 
    searchSection: string,
    lastSearchSection: string, 
    mode: string, 
    lastInternalFileIndex: any, 
    currentFolder: any, 
    directory: string,
    unconfirmedSelection: any, 
    selectedFile: any,
    selectedFolder: any, 
    folderData: any[],
    folderDataBeforeQuery: any[], 
    foldersList: any[], 
    folderView: string, 
    isDropdownOpened: boolean,
    patientsFolder: any, 
    documentsFolder: any, 
    downloadsFolder: any, 
    consultations: any[], 
    isRefreshConsultations: boolean, 
    unconfirmedDraggedFile: any, 
    confirmedDraggedFile: any, 
    mouseInfo: any, 
    isComingFromTemplateEditing: boolean, 
    initialRendering: boolean, 
}

const initialState: InitialState = {
    refreshFileExplorer: false, 
    searchQuery: 'no-search', 
    searchSection: '',
    lastSearchSection: '', 
    mode: '', 
    lastInternalFileIndex: null, // For the search query
    currentFolder: null, 
    directory: '', 
    unconfirmedSelection: null, 
    selectedFile: null,
    selectedFolder: null, 
    folderData: [],
    folderDataBeforeQuery: [], 
    foldersList: [], 
    folderView: 'box', 
    isDropdownOpened: false,
    patientsFolder: null, 
    documentsFolder: null, 
    downloadsFolder: null, 
    unconfirmedDraggedFile: null, 
    consultations: [], 
    isRefreshConsultations: false, 
    confirmedDraggedFile: null, 
    mouseInfo: null, // For the dropwdown list ,
    isComingFromTemplateEditing: false, 
    initialRendering: true, 
}

export const fileExplorerSlice = createSlice({
  name: 'fileExplorer',
  initialState,
    reducers: {

    setRefreshFileExplorer: (state, action: PayloadAction<boolean>) => { 
      state.refreshFileExplorer = action.payload; 
    },  
    setSearchQuery: (state, action: PayloadAction<string>) => { 
        state.searchQuery = action.payload; 
    },  
    setSearchSection: (state, action: PayloadAction<string>) => {
        state.searchSection = action.payload; 
    },
    setLastSearchSection: (state, action: PayloadAction<string>) => {
      state.lastSearchSection = action.payload; 
    },
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload
    },
    setLastInternalFileIndex: (state, action: PayloadAction<any>) => {
      state.lastInternalFileIndex = action.payload
    },
    setCurrentFolder: (state, action: PayloadAction<any>) => {
      state.currentFolder = action.payload
      state.searchSection = action.payload._id; 
      state.directory = action.payload ? action.payload.path : ''; 
    },
    setDirectory: (state, action: PayloadAction<string>) => {
        state.directory = action.payload;  
    },
    setUnconfirmedSelection: (state, action: PayloadAction<any>) => {
      state.unconfirmedSelection = action.payload
    },
    selectFile: (state, action: PayloadAction<any>) => {
      state.selectedFile = action.payload
    },
    selectFolder: (state, action: PayloadAction<any>) => {
      state.selectedFolder = action.payload
    },
    setFolderData: (state, action: PayloadAction<any>) => { 
      state.folderData = action.payload
    }, 
    setFolderDataBeforeQuery: (state, action: PayloadAction<any>) => { 
      state.folderDataBeforeQuery = action.payload
    }, 
    setFoldersList: (state, action: PayloadAction<any>) => { 
      state.foldersList = action.payload
    }, 
    setFolderView: (state, action: PayloadAction<any>) => { 

      action.payload ? state.folderView = action.payload : state.folderView = 'row'; 

    }, 
    setIsDropdownOpened: (state, action: PayloadAction<boolean>) => { 
      state.isDropdownOpened = action.payload
    }, 
    setPatientsFolder: (state, action: PayloadAction<any>) => { 
      state.patientsFolder = action.payload
    }, 
    setDocumentsFolder: (state, action: PayloadAction<any>) => { 
      state.documentsFolder = action.payload
    }, 
    setDownloadsFolder: (state, action: PayloadAction<any>) => { 
      state.downloadsFolder = action.payload
    }, 
    setConsultations: (state, action: PayloadAction<any>) => { 
      state.consultations = action.payload
    }, 
    setIsRefreshConsultations: (state, action: PayloadAction<boolean>) => { 
      state.isRefreshConsultations = action.payload
    }, 
    setUnconfirmedDraggedFile: (state, action: PayloadAction<any>) => { 
      state.unconfirmedDraggedFile = action.payload
    }, 
    setConfirmedDraggedFile: (state, action: PayloadAction<any>) => { 
      state.confirmedDraggedFile = action.payload
    }, 
    setMouseInfo: (state, action: PayloadAction<any>) => { 
      state.mouseInfo = action.payload
    }, 
    setIsComingFromTemplateEditing: (state, action: PayloadAction<boolean>) => {
  
      state.isComingFromTemplateEditing = action.payload; 

    }, 
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    resetFileExplorer: (state) => { 
        
        state.refreshFileExplorer = false; 
        state.searchQuery = 'no-search'; 
        state.searchSection = '';
        state.lastSearchSection = ''; 
        state.mode = ''; 
        state.lastInternalFileIndex = null; 
        state.currentFolder = null; 
        state.directory = '';
        state.unconfirmedSelection = null; 
        state.selectedFile = null;
        state.selectedFolder = null; 
        state.folderData = [];
        state.folderDataBeforeQuery = []; 
        state.foldersList = []; 
        state.folderView = 'row'; 
        state.isDropdownOpened = false; 
        state.patientsFolder = null; 
        state.documentsFolder = null; 
        state.downloadsFolder = null; 
        state.consultations = []; 
        state.isRefreshConsultations = false; 
        state.unconfirmedDraggedFile = null;
        state.confirmedDraggedFile = null; 
        state.mouseInfo = null; 
        state.isComingFromTemplateEditing = false; 
        state.initialRendering = true; 

    } 
  },
});

export const {
    setRefreshFileExplorer, 
    setSearchQuery, 
    setSearchSection,
    setLastSearchSection,
    setMode, 
    setLastInternalFileIndex, 
    setCurrentFolder, 
    setDirectory,
    setUnconfirmedSelection, 
    selectFile,
    selectFolder, 
    setFolderData,
    setFolderDataBeforeQuery, 
    setFoldersList, 
    setFolderView, 
    setIsDropdownOpened,
    setPatientsFolder, 
    setDocumentsFolder,
    setDownloadsFolder, 
    setConsultations, 
    setIsRefreshConsultations, 
    setUnconfirmedDraggedFile, 
    setConfirmedDraggedFile, 
    setMouseInfo,  
    setIsComingFromTemplateEditing, 
    setInitialRendering, 
    resetFileExplorer,
} = fileExplorerSlice.actions;

export default fileExplorerSlice.reducer;