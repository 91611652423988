import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

// Redux 

import { setOpenPopup, setPopupAction } from '../../../../../../redux/features/general/popup';

const ExitButton: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch(); 

  const colors = useAppSelector(state => state.theme.colors);
  const section = useAppSelector(state => state.createPatient.section); 

  // -- 

    const handleOpenPopup = () => { 

        dispatch(setOpenPopup()); 
        dispatch(setPopupAction('cancelPatientCreation')); 
    }; 

  // -- 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 2,
        marginBottom: 5,
        height: '5vh',
        width: '100%',
        bottom: 0,
        postion: 'absolute'
    }}>

        <Button 
          sx={{ 
            width: 250,
            backgroundColor: colors?.cancelButtonBackground,
            borderColor: colors?.cancelButtonBackground,
            color: colors?.text,
            '&:hover': 
            { 
                backgroundColor: colors?.cancelButtonBackground,
                color: colors?.text,
                borderColor: colors?.cancelButtonBackground
             }
          }}
          onClick={() => handleOpenPopup()}
          variant="outlined">{t('exit')}</Button>


    </Box>

  );

};

export default ExitButton;