import React, { useState, useEffect, useRef } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components 

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { setMode } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { resetImageViewer } from '../../../../../../redux/features/doctor/Dashboard/documents/imageViewer';

const Header: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const { t } = useTranslation(); 

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');
    const leftOffset = isSmallScreen ? '60px' : '5vw';

    const colors = useAppSelector(state => state.theme.colors); 

    const selectedImage = useAppSelector(state => state.imageViewer.selectedImage); 

    // -- 

    const leaveImageViewer = () => { 

        dispatch(resetImageViewer()); 

    }; 

    // --

    return (

        <Box 
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                top: 0, 
                width: '100vw',
                height: 50, 
                overflow: 'hidden', 
                position: 'absolute', 
            
        }}>

            <Box
                component="img"
                sx={{
                    height: `${20}px`,
                    width: 'auto',
                    position: 'absolute',
                    top: 15,
                    left: 15, 
                }}
                alt="Solutions Medca Inc."
                src={require('../../../../../../assets/logos/medca_logo_blue_dark_blue.png')}
            />

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    color: 'rgb(150, 150, 150)', 
                    fontSize: 12, 
                }}
            >{selectedImage?.title}
            </Box>

            <CancelOutlinedIcon 
                onClick={leaveImageViewer}
                sx={{ 
                    fontSize: 30, 
                    color: colors?.cancelButtonText, 
                    cursor: 'pointer', 
                    position: 'absolute',
                    top: 15,
                    right: 15, 
                    '&:hover': { color: colors?.cancelButtonColor },
                }} />

        </Box>
  );
};

export default Header;