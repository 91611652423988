import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import OptionBox from './OptionBox';
import GroupsIcon from '@mui/icons-material/Groups';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ShieldMoonIcon from '@mui/icons-material/ShieldMoon';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AddAlertIcon from '@mui/icons-material/AddAlert';

const Options: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'center', 
          width: '100%',
          height: '60%', 
      }}>

        
            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    width: '80%', 
                }}
            >

                { !process.env.REACT_APP_BACKEND_URL?.includes('https') &&

                <OptionBox 
                    title={'Calendar'} 
                    section={'calendar'}
                    data={'Upcoming Events'} 
                    hoverColor={colors?.calendarBackground}
                    backgroundColor={colors?.calendarHover}>
                    <EventNoteIcon sx={{ color: colors?.calendarIcon }} />
                </OptionBox>} 


                { !process.env.REACT_APP_BACKEND_URL?.includes('https') &&

                <OptionBox 
                    title={'Notifications'} 
                    section={'notifications'}
                    data={'General Updates'} 
                    hoverColor={colors?.notificationsBackground}
                    backgroundColor={colors?.notificationsHover}>
                    <AddAlertIcon sx={{ color: colors?.notificationsIcon }} />
                </OptionBox>}

                <OptionBox 
                    title={'Access Requests'} 
                    section={'accessRequests'}
                    data={'Pending Requests'} 
                    hoverColor={colors?.accessRequestsBackground}
                    backgroundColor={colors?.accessRequestsHover}>
                    <ShieldMoonIcon sx={{ color: colors?.accessRequestsIcons }} />
                </OptionBox>

            </Box>

      </Box>

  );

};

export default Options;