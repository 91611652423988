import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components

import FolderIcon from '@mui/icons-material/Folder';
import { getDoctorName } from '../../../../../../../routes/doctor/patients';

interface Props { 
    doctorId: string; 
    updatedAt: string; 
}; 

const ConsultationRow: React.FC<Props> = ({ doctorId, updatedAt }) => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);  

    const [doctorFullName, setDoctorFullName] = useState(''); 

    // -- 

    const handleDoctorFullName = async () => { 

        const res = await getDoctorName(doctorId); 

        if (res?.data.status === 200) { 

            setDoctorFullName(res.data.fullName); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (doctorId) { 

            handleDoctorFullName(); 

        };

    },[doctorId]); 

    return (

        <>

            <Box 
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                fontSize: 12, 
                height: 50,
                cursor: 'pointer',
                backgroundColor: 'transparent',
                '&:hover': { backgroundColor: colors?.sectionHover }
            }}>
        
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%', 
                    marginLeft: '2%',  
                }}>

                    <FolderIcon sx={{ color: colors?.folder, fontSize: 20, marginLeft: 1 }} />

                </div>
                
                <div style={{ 
                    width: '70%',
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}>{moment.utc(updatedAt).format('L')}_{doctorFullName}</div>

                <div style={{ 
                    width: '10%', 
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden', 
                }}>{moment.utc(updatedAt).format('L')}</div>
    
            </Box>

        </>

    );
};

export default ConsultationRow;