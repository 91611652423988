import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux 

import { setFile, setIsFileUploaded } from '../../../../../../redux/features/doctor/Dashboard/documents/uploadFile';


// Icons 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const UploadFileButton: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const fileInput = document.getElementById("fileItem") as any; 

    const isFileUploaded = useAppSelector(state => state.uploadFile.isFileUploaded); 

    // --

    fileInput?.addEventListener("change", () => {

        dispatch(setFile(fileInput.files[0])); 

    });

    // -- 

    useEffect(() => { 

        if (isFileUploaded)  {

            fileInput.value = null; 

            dispatch(setIsFileUploaded(false)); 

        };

    },[isFileUploaded]);

    return (

        <Button
            component="label"
            sx={{ 
                all: 'initial',                    
                width: 100, 
                height: 30,
                position: 'absolute', 
                cursor: 'pointer',
                '&:hover': { backgroundColor: 'transparent'},
                zIndex: 1}}
            >
                <input
                    id="fileItem"
                    type="file"
                    // accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    accept='application/pdf'
                    hidden
                />
        </Button>

    );

};

export default UploadFileButton;