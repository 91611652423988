import React, { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setIsAttributesDropdownOpened, setIsRefreshFieldsList, setNewTextFieldAttribute, setSelectedTextField, setTextFields, updateTextFields } from '../../../../../redux/features/template/template';

// Components 

import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { updateTemplateTextField } from '../../../../../routes/doctor/templates';

type TitleProps = {
    children: React.ReactNode, 
    title: string, 
};

const AttributeRow: React.FC<TitleProps> = ({ children, title }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const newTextFieldAttribute = useAppSelector(state => state.template.newTextFieldAttribute); 
    const selectedTextFieldId = useAppSelector(state => state.template.selectedTextFieldId); 
    const selectedTextFieldIndex = useAppSelector(state => state.template.selectedTextFieldIndex);  
    const textFields = useAppSelector(state => state.template.textFields); 

    // -- 

    const handleSelection = async () => { 
        
        const content = {
            textFieldId: selectedTextFieldId,
            attribute: title, 
        }; 

        const res = await updateTemplateTextField(content) as any; 

        if (res.data.status == 200) { 

            dispatch(updateTextFields({ 
                selectedTextFieldId: selectedTextFieldId,
                updatedTextField: res.data.templateTextField,
                index: selectedTextFieldIndex
            })); 

            dispatch(setSelectedTextField(''));

        }; 


        dispatch(setIsAttributesDropdownOpened(false));

        
    }; 

    // -- 

    return (

        <Box 
            onClick={handleSelection}
            sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between', 
            width: '95%',
            minHeight: 40, 
            marginBottom: 1, 
            borderRadius: 1, 
            paddingLeft: 1, 
            paddingRight: 1, 
            overflowY: 'hidden',
            backgroundColor: colors?.formRow,
            color: colors?.text,
            cursor: 'pointer',
            '&:hover': { backgroundColor: colors?.formRowHover},
            transition: 'height 0.3s',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {children}
            </Box>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ marginRight: 1,  fontSize: 12 }}>{t(title)}</Box>

            </Box>



        </Box>
  );
};

export default AttributeRow;