import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const Header: React.FC = () => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);  

    const [text, setText] = useState(''); 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly', 
            minHeight: '10%',
            marginBottom: 1, 
            width: '100%',
            fontSize: 12,     
            color: colors?.text,
            flexShrink: 0,
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '100%',
                    paddingLeft: '2%', 
                    fontSize: 15
                }}
            >{t('selectPatientToStart')}
            </Box>

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '60%',
                marginLeft: '2%',
                height: 30, 
                borderRadius: 2,
                backgroundColor: colors?.createTemplate,
            }}>
                <SearchIcon sx={{ fontSize: 12, marginLeft: 2, color: colors?.text }}/>
                <TextField
                    // onKeyDown={hanleEnterOption}
                    id="standard-search"
                    placeholder={String(t('searchPatient'))}
                    variant="standard"
                    autoComplete='off'
                    value={text}
                    InputProps={{
                        style: {fontSize: 12, color: colors?.text },
                        disableUnderline: true, 
                    }}
                    onChange={(e) => setText(e.target.value)}
                    sx={{
                        marginLeft: 2,
                        fontSize: 12,
                        width: '100%',
                        borderBottomColor: colors?.templateSearch
                    }}
                />

            </Box>
            

        </Box>
    );
};

export default Header;