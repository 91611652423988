import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Sidebar from './sidebar/Sidebar';
import { setConsultationNoteId } from '../../redux/features/doctor/Dashboard/consultation/consultationNote';
import Document from './document/Document';
import Navbar from './navbar/Navbar';
import Popup from '../general/Popup';
import ExitPopup from './navbar/items/ExitPopup';
import AccessPopup from './document/AccessPopup';

// -- 

const Container: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { consultationNoteId } = useParams() as any; 

    const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 
    const isSavingEnabled = useAppSelector(state => state.consultationNote.isSavingEnabled); 
    const action = useAppSelector(state => state.popup.action); 

    // -- 

    window.onbeforeunload = function (e: any) {

      if (isSavingEnabled) { 
  
        // For IE and Firefox
  
        if (e) {
          e.returnValue;
        }; 
      
        // For Safari
        return e.returnValue;
  
      };
  
    };

    // -- 

    useEffect(() => { 

        if (consultationNoteId) { 

            dispatch(setConsultationNoteId(consultationNoteId)); 

        }; 

    },[consultationNoteId]); 

    // -- 

    return (

      <Box
        sx={{ 
          width: '100%', 
          height: '100%', 
        }}
      >

        <Sidebar /> 

        <Document /> 

        <Navbar /> 

        { isPopupOpened && 
          <Popup>
            { action === 'exitConsultationNote' && <ExitPopup />} 
            { action === 'securityPin' && <AccessPopup />}
          </Popup>}

      </Box>

  );

};

export default Container;