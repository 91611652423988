import React, { useState, useEffect } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import CategoryRow from './categories/CategoryRow';

const Categories: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const navigate = useNavigate(); 

  const { t } = useTranslation(); 

  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '5vw';

  const colors = useAppSelector(state => state.theme.colors);

  const section = useAppSelector(state => state.consultation.section); 
  const notes = useAppSelector((state) => state.consultation.notes); 
  const attachedFiles = useAppSelector((state) => state.consultation.attachedFiles); 
  const images = useAppSelector((state) => state.consultation.images); 

  // --

  return (

    <Box style={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: isSmallScreen ? '60px' : '15%',
        height: '95%', 
        borderRadius: 10,
        backgroundColor: colors?.container,
        overflowY: 'auto',
        overflowX: 'hidden',
        transition: 'width 0.3s',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        paddingTop: 30
    }}>

      <CategoryRow category='actions' numberOfItems={0}/>

      <CategoryRow category='notes' numberOfItems={notes?.length}/>

      <CategoryRow category='files' numberOfItems={attachedFiles?.length}/>

      <CategoryRow category='images' numberOfItems={images?.length}/>

    </Box>
    
  );
};

export default Categories;