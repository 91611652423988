import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import AddToDriveOutlinedIcon from '@mui/icons-material/AddToDriveOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';

import Action from './Action';

const ActionsRow: React.FC = () => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);  

    return (

        <Box 
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 30, 
                width: '100%', 
        }}>

            <Action title={'note'} type='default' iconColor={colors?.purple} action={'newNote'}>
                <AddCommentOutlinedIcon /> 
            </Action>

            <Action title={'attachFile'} type='default' iconColor={colors?.file} action={'attachFile'}>
                <AddToDriveOutlinedIcon /> 
            </Action>

            <Action title={'image'} type='default' iconColor={colors?.darkPurple} action={'uploadImage'}>
                <AddPhotoAlternateOutlinedIcon /> 
            </Action>

            <Action title={'uploadFile'} type='default' iconColor={colors?.green} action={'uploadFile'}>
                <DownloadOutlinedIcon /> 
            </Action>

            { !process.env.REACT_APP_BACKEND_URL?.includes('https') &&
            <Action title={'template'} type='default' iconColor={colors?.darkBlue} action={'useTemplate'}>
                <ContactPageOutlinedIcon /> 
            </Action>}

            { !process.env.REACT_APP_BACKEND_URL?.includes('https') &&
            <Action title={'takePicture'} type='default' iconColor={colors?.red} action={'takePicture'}>
                <AddAPhotoOutlinedIcon /> 
            </Action>}

        </Box>
    );
};

export default ActionsRow;