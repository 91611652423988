import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { serveConsultationImage, serveConsultationNote } from '../../../../../../routes/doctor/consultation';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { serveFile } from '../../../../../../routes/doctor/files';

const ConsultationImageViewer: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const { t } = useTranslation(); 

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');
    const leftOffset = isSmallScreen ? '60px' : '5vw';

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const colors = useAppSelector(state => state.theme.colors);

    const selectedImage = useAppSelector(state => state.savedConsultation.selectedImage) as any; 

    const [fileUrl, setFileUrl] = useState(''); 

    // -- 

    const handleImage = async () => { 
        
        const res = await serveFile(selectedImage._id, 'image') as any; 

        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });

        setFileUrl(URL.createObjectURL(fileBlob));  

    }; 

    // -- 

    useEffect(() => { 

        if (selectedImage) { 

            handleImage(); 

        }; 

    },[selectedImage]); 

    // --

    return (

        <Box
            sx={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: '80%',
                height: '95%', 
                position: 'absolute',
                padding: 2, 
                backgroundColor: colors?.container,
                overflow: 'scroll'
            }}>


                {fileUrl && 
                <Box
                    id='image-viewer'
                    component="img"
                    sx={{
                        height: 'auto',
                        width: 'auto',
                    }}
                    alt="Consultation Image"
                    src={fileUrl}
                />}
                        
        </Box>

    );

};

export default ConsultationImageViewer;