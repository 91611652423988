import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import {  PDFDocument } from 'pdf-lib';
import { useNavigate } from 'react-router-dom';

// Redux 

// Components 

import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import LogoutIcon from '@mui/icons-material/Logout';
import { setOpenPopup, setPopupAction } from '../../../../redux/features/general/popup';
import { saveConsultationNote } from '../../../../routes/doctor/consultation';
import { resetConsultationNote } from '../../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import CircularProgress from '@mui/material/CircularProgress';
import { setIsNewNoteCreated } from '../../../../redux/features/doctor/Dashboard/consultation/consultation';

const SavingOptions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const consultationNoteId = useAppSelector(state => state.consultationNote.consultationNoteId); 
    const isSavingEnabled = useAppSelector(state => state.consultationNote.isSavingEnabled); 
    const mode = useAppSelector(state => state.consultationNote.mode); 

    const [isSavingButtonDisabled, setIsSavingButtonDisabled] = useState(false); 

    // -- 

    const handleQuit = () => { 

        dispatch(setPopupAction('exitConsultationNote')); 
        dispatch(setOpenPopup()); 
        
    }; 

    // -- 

    const handleSaveNote = async () => { 

        if (isSavingButtonDisabled) { 
            return; 
        }; 

        setIsSavingButtonDisabled(true); 

        const documentComponent = document.getElementById('consultationNote-pdf') as any;

        const canvas = await html2canvas(documentComponent);

        const imgData = canvas.toDataURL('image/png');

        const pdfDoc = await PDFDocument.create();

        const image = await pdfDoc.embedPng(imgData);

        const page = pdfDoc.addPage([image.width, image.height]);
        page.drawImage(image, { x: 0, y: 0, width: image.width, height: image.height });

        const pdfBytes = await pdfDoc.save();

        const titlePlaceHolder = `note_${consultationNoteId.slice(0, 20)}_${Math.floor(Math.random() * (100000 - 1 + 1) + 1)}.pdf`; 

        const newFile = new File([pdfBytes], titlePlaceHolder, { type: "application/pdf" });

        const formData = new FormData() as any; 

        formData.append('file', newFile); 
        formData.append('consultationNoteId', consultationNoteId); 
        formData.append('title', titlePlaceHolder); 

        const res = await saveConsultationNote(formData) as any;

        if (res.status === 200) {

            setIsSavingButtonDisabled(false); 

            dispatch(resetConsultationNote()); 

            dispatch(setIsNewNoteCreated(true)); 

            navigate('/dashboard/consultation'); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (mode === 'saveConsultationNote') { 

            if (isSavingEnabled) { 

                handleSaveNote(); 

            }; 

        }; 

    },[mode]); 
    
    // -- 

    return (

        <Box 
            sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
        }}>

            {isSavingButtonDisabled && <CircularProgress size={15} />}
        
            <Box
                onClick={handleSaveNote}
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    padding: 1, 
                    fontSize: 12, 
                    marginLeft: 3, 
                    borderRadius: 1, 
                    marginRight: 2, 
                    height: 30,
                    color: colors?.text, 
                    cursor: isSavingEnabled ? 'pointer' : 'default', 
                    backgroundColor: isSavingEnabled ? colors?.navbarToolEnabled : colors?.navbarToolDisabled, 
                    '&:hover': { backgroundColor: isSavingEnabled ? colors?.navbarToolEnabledHover : colors?.navbarToolDisabled }
                }}
            >

                <Box>{t('saveConsultationNote')}</Box>

                <DownloadIcon sx={{ marginLeft: 1, fontSize: 16 }} /> 

            </Box>

            <Box
                onClick={handleQuit}
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    padding: 1, 
                    fontSize: 12, 
                    marginLeft: 1, 
                    borderRadius: 1, 
                    marginRight: 2, 
                    height: 30,
                    color: colors?.text, 
                    cursor: 'pointer', 
                    backgroundColor: colors?.consultationQuit, 
                    '&:hover': { backgroundColor: colors?.consultationQuitHover }
                }}
            >

                <LogoutIcon sx={{ fontSize: 16 }} /> 

            </Box>

        </Box>

  );

};

export default SavingOptions;