import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

interface Props { 
    patientId: string,
    fullName: string,
    index: any,
    isLastIndex: boolean,
}; 

const PatientRow: React.FC<Props> = ({ patientId, fullName, index, isLastIndex }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-between', 
            width: '100%',
            height: 40, 
            color: colors?.text,
            borderTopLeftRadius: index === 0 ? 5 : 0, 
            borderTopRightRadius: index === 0 ? 5 : 0, 
            borderBottomLeftRadius: isLastIndex ? 5 : 0, 
            borderBottomRightRadius: isLastIndex ? 5 : 0, 
            backgroundColor: colors?.patientSearchRow,
            '&:hover': { backgroundColor: colors?.patientSearchDropdownRowHover }
        }}>

            <Box sx={{ marginLeft: 2}}>{fullName}</Box>
            <Box sx={{ marginRight: 3 }}>{patientId}</Box>

        </Box>

    );

};

export default PatientRow;