import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import { setInfoSection } from '../../../../../../../../redux/features/doctor/Dashboard/patients/patient';

interface Props { 
    children?: React.ReactNode
    title: string,
    section: string, 
    data: any, 
    hoverColor?: string,
    backgroundColor?: string,
}

const OptionBox: React.FC<Props> = ({ children, title, section, data, hoverColor, backgroundColor }) => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    const handleAction = () => { 

        if (section === 'support') { 

            navigate('/support'); 

        } else if (section === 'calendar') { 

            navigate('/calendar'); 

        } else { 

            dispatch(setInfoSection(section)); 
            
        }; 

    }; 

    // -- 

    return (

      <Box 
        onClick={handleAction}
        sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            cursor: 'pointer', 
            width: process.env.REACT_APP_BACKEND_URL?.includes('https') ? 500 : 275,
            height: 100, 
            marginLeft: 2,
            marginRight: 2, 
            marginBottom: 2, 
            borderRadius: 3,
            backgroundColor: backgroundColor,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            '&:hover': { backgroundColor: hoverColor }
      }}>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                paddingLeft: 3,
                paddingTop: 2, 
                width: '100%',
                fontSize: 15, 
                height: '20%', 
                color: colors?.text
            }}
        >{title}

        </Box>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                height: '80%', 
                color: colors?.text
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    fontSize: 40,
                    paddingLeft: 3,
                    height: '100%', 
                }}
            >
                {children}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    marginLeft: 2,
                    fontSize: 12,
                    height: '100%', 
                    color: colors?.text
                }}
            >
                {data}
            </Box>

        </Box>

      </Box>

  );

};

export default OptionBox;