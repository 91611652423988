import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Components 

import PatientsList from './PatientsList';
import CreatePatientButton from './CreatePatientButton';
import OverviewButton from './OverviewButton';

const NavigationColumn: React.FC = () => {

  const colors = useAppSelector(state => state.theme.colors); 

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',  
        width: '20%',
        height: '95%',
        borderRadius: 5,
        backgroundColor: colors?.container,
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    }}>

      <OverviewButton /> 
      
      <PatientsList /> 

      <CreatePatientButton /> 

    </Box>
  );
};

export default NavigationColumn;