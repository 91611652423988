import React, { useState, useEffect } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components 

import ActionRow from './ActionRow';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import QuitButton from './QuitButton';

const Actions: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const navigate = useNavigate(); 

  const { t } = useTranslation(); 

  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '5vw';

  const colors = useAppSelector(state => state.theme.colors);

  const section = useAppSelector(state => state.consultation.section); 

  // --

  return (

    <Box style={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: isSmallScreen ? '60px' : '15%',
        height: '95%', 
        borderRadius: 10,
        backgroundColor: colors?.container,
        overflowY: 'auto',
        overflowX: 'hidden',
        transition: 'width 0.3s',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        paddingTop: 30
    }}>

      <QuitButton /> 

      <ActionRow action='deleteConsultationNote'>
          <DeleteIcon sx={{ color: colors?.red }} /> 
      </ActionRow>

    </Box>
    
  );
};

export default Actions;