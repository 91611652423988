import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
// Redux 

import { setClosePopup } from '../../../../../../redux/features/general/popup';
import { useTranslation } from 'react-i18next';
import { resetConsultation } from '../../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { deleteConsultation } from '../../../../../../routes/doctor/consultation';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const ExitPopup: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const consultationId = useAppSelector(state => state.consultation.consultationId); 

    // -- 

    const cancelConsultation = async () => { 

        const res = await deleteConsultation(consultationId) as any; 

        if (res.data.status === 200) { 

            dispatch(resetConsultation()); 

            dispatch(setClosePopup()); 

        }; 

    }; 

    // -- 

    const goBackToConsultation = () => { 

        dispatch(setClosePopup()); 

    }; 


  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: colors?.container,
        overflowY: 'auto',
        overflowX: 'hidden',
    }}>

        <Box>{t('areYouSure')}</Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%'}}>
        <Button 
          sx={{ 
            width: 200,
            backgroundColor: colors?.exitButtonColor,
            borderColor: colors?.exitButtonColor,
            color: 'white',
            marginBottom: 2,
            '&:hover': 
            { 
                backgroundColor: colors?.exitButtonColorHover,
                color: colors?.exitButtonText,
                borderColor: colors?.exitButtonColorHover
             }
          }}
          onClick={() => cancelConsultation()}
          variant="outlined">{t('exit')}</Button>
                  <Button 
          sx={{ 
            width: 200,
            backgroundColor: colors?.cancelButtonColor, 
            borderColor: colors?.text,
            color: colors?.text,
            '&:hover': 
            { 
                backgroundColor: colors?.cancelButtonBackground,
                color: colors?.text,
                borderColor: colors?.cancelButtonBackground
             }
          }}
          onClick={() => goBackToConsultation()}
          variant="outlined">{t('back')}</Button>
          </Box>

    </Box>
  );
};

export default ExitPopup;