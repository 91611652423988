import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setFontSize, setFontStyle, setFontWeight, setIsCreatingTextField, setIsRefreshFieldsList, setNewTextFieldPosition, setSelectedTextField, setTextAlign, setTextDecoration, updateTextFields } from '../../../../redux/features/template/template';

// Components 

import Box from '@mui/material/Box';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';

import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { setTemplatesSection } from '../../../../redux/features/doctor/Dashboard/templates/templates';
import { updateTemplateTextField } from '../../../../routes/doctor/templates';

const TextStructure: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const textAlign = useAppSelector(state => state.template.textAlign); 
    const isCreatingTextField = useAppSelector(state => state.template.isCreatingTextField); 
    const selectedTextFieldIndex = useAppSelector(state => state.template.selectedTextFieldIndex);
    const selectedTextFieldId = useAppSelector(state => state.template.selectedTextFieldId); 

    // -- 

    const handleTextStructure = (type: string) => { 

      if (type === 'textFieldCreation') { 

        isCreatingTextField ? dispatch(setIsCreatingTextField(false)) : dispatch(setIsCreatingTextField(true));
        isCreatingTextField ? dispatch(setNewTextFieldPosition(null)) :  dispatch(setTemplatesSection('forms')); 

        dispatch(setSelectedTextField(null));

      }; 

    }; 

    const updateTextAlign = async () => { 

        dispatch(setTextAlign(getTextAlign())); 

        const style = { 
            textAlign: getTextAlign()
        };

        if (selectedTextFieldId) { 

          const content = { 
            textFieldId: selectedTextFieldId,
            style: style, 
          }; 
    
          const res = await updateTemplateTextField(content) as any; 
      
          if (res.data.status == 200) {
      
            dispatch(updateTextFields({ 
                selectedTextFieldId: selectedTextFieldId,
                updatedTextField: res.data.templateTextField,
                index: selectedTextFieldIndex
            })); 
      
            dispatch(setSelectedTextField({ textField: res.data.templateTextField, index: selectedTextFieldIndex }));
      
          }; 

        }; 
        
    };

    // -- 

    const getTextAlign = () => { 

      if (textAlign === 'left') { 

        return 'center'; 

      } else if (textAlign === 'center') { 

        return 'right'; 

      } else if (textAlign === 'right') { 

        return 'justify'; 

      } else if (textAlign === 'justify') { 

        return 'left'; 

      } else { 

        return 'left'; 

      }; 

    }; 
        
    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          height: '100%',
          marginLeft: 1,
          color: colors?.text
      }}>

          <Box
            onClick={() => handleTextStructure('textFieldCreation')}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30,
                height: 30,  
                borderRadius: 1, 
                marginLeft: 1,
                fontSize: 12,
                cursor: 'pointer',
                backgroundColor: isCreatingTextField ? colors?.templateToolEnabled : colors?.templateToolDisabled,
                '&:hover': { backgroundColor: isCreatingTextField ? colors?.templateToolEnabledHover : colors?.templateToolDisabled }
            }}
        >
          <FormatShapesIcon sx={{ fontSize: 14, color: colors?.text}} />
        </Box>

        <Box
            onClick={updateTextAlign}
            sx={[{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30,
                height: 30,  
                backgroundColor: colors?.templateToolDisabled,
                borderRadius: 1, 
                marginLeft: 1,
                fontSize: 12,
                cursor: 'pointer',
                '&:hover': { backgroundColor: colors?.templateToolEnabledHover }
            }]}
        >

           { textAlign === 'left' && <FormatAlignLeftIcon sx={{ fontSize: 14, color: colors?.text}} />}
           { textAlign === 'right' && <FormatAlignRightIcon sx={{ fontSize: 14, color: colors?.text}} />}
           { textAlign === 'center' && <FormatAlignCenterIcon sx={{ fontSize: 14, color: colors?.text}} />}
           { textAlign === 'justify' && <FormatAlignJustifyIcon sx={{ fontSize: 14, color: colors?.text}} />}

        </Box>

      </Box>

  );

};

export default TextStructure;