import React from 'react';
import Box from '@mui/material/Box';

const Logo: React.FC = () => {

  return (
    <Box
      component="img"
      sx={{
        height: 20,
        width: 'auto',
      }}
      alt="Solutions Medca Inc."
      src={require('../../../../assets/logos/medca_logo_blue_dark_blue.png')}
    />
  );
};

export default Logo;