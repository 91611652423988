// External imports
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider as MuiThemeProvider, Container } from '@mui/material';
import Cookies from 'js-cookie';

// Local imports
import HomePage from './pages/Home';
import LogIn from './pages/LogIn';
import Dashboard from './pages/Dashboard';
import theme from './theme';
import Admin from './pages/HackerLogin';
import Support from './pages/Support'; 
import Hacker from './pages/Hacker';
import Tos from './pages/Tos';
import Faq from './pages/Faq';
import Legal from './pages/Legal';
import Privacy from './pages/Privacy';
import Settings from './pages/Settings';
import ScanTemplate from './pages/ScanTemplate';
import { useAppDispatch } from './redux/hooks';
import { setAccountType, setAccountInfo } from './redux/features/general/meta';

// Routes 
import { verifyUserAccess } from './routes/general/authentication';
import publicRoutes from './util/navigation/routes/publicRoutes';

// Context import
import { ThemeProvider } from './themeContext';
import AccessRequest from './pages/AccessRequest';
import clearCookies from './util/storage/clearCookies';
import clearLocalStorage from './util/storage/clearLocalStorage';
import Template from './pages/Template';
import { setColors } from './redux/features/general/theme';
import lightTheme from './styles/themes/lightTheme';
import darkTheme from './styles/themes/darkTheme';
import ConsultationNote from './pages/ConsultationNote';
import NoteMicrophone from './pages/NoteMicrophone';
import MobileLogin from './pages/MobileLogin';

function App() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(''); 

  // -- 

  const getAccount = async (type: string) => {

    const res = await verifyUserAccess(type);

    if (res?.status === 200) {

      dispatch(setAccountType(res.data.accountType));
      dispatch(setAccountInfo(res.data.account)); 

      if (res.data.accountType === 'doctor') { 

        document.cookie = `jwt=${res.data.token}; path=/`;

        if (!currentPath || (currentPath === '/')) { 

          navigate('/dashboard');

        };
 
      } else if (res.data.accountType === 'hacker') { 

        document.cookie = `adminJwt=${res.data.token}; path=/`;

        navigate('/hacker'); 

      }; 
      
    } else { 

      clearCookies();
      clearLocalStorage(); 

      window.location.reload();

      navigate('/login'); 

    }; 

  };

  // -- 

  useEffect(() => {

    setCurrentPath(location.pathname); 

  },[location.pathname]); 

  // -- 

  useEffect(() => { 

    const savedTheme = localStorage.getItem('theme');

    dispatch(setColors(lightTheme)); 
    
    // if ((savedTheme === 'light') || !savedTheme) { 

    //   dispatch(setColors(lightTheme)); 

    // } else if (savedTheme === 'dark') { 

    //   dispatch(setColors(darkTheme)); 

    // }; 

  },[]); 

  // -- 

  useEffect(() => {

    if (currentPath && publicRoutes) { 
  
      const isPublicRoute = publicRoutes.some(route =>
        typeof route === 'string' ? currentPath == route : route.test(currentPath)
      );

      // Temporary FIX for the mic consultation route. 
    
      if ((!isPublicRoute) && (!currentPath.includes('consultation/note/mic'))) {
        
        const jwt = Cookies.get('jwt') as string;
        const adminJwt = Cookies.get('adminJwt') as string;

        if (jwt && (currentPath !== '/hacker')) {

          getAccount('doctor');

        } else if (adminJwt) {

          getAccount('hacker');

        } else {

          navigate('/login');

        };

      };

    };

  }, [currentPath]);

  // --

  return (
    <ThemeProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="login" element={<LogIn />} />
            <Route path="login/mobile/consultation/note/:consultationNoteId" element={<MobileLogin />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="dashboard/:currentSection" element={<Dashboard />} /> 
            <Route path="/scan-template/:tempId" element={<ScanTemplate />} />
            <Route path="hacker/login" element={<Admin />} />
            <Route path="hacker" element={<Hacker />} />
            <Route path="support" element={<Support />} />
            <Route path='settings' element={<Settings />} /> 
            <Route path="tos" element={<Tos />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="faq" element={<Faq />} />
            <Route path="legal" element={<Legal />} />
            <Route path='patient/access/request/:patientAccessRequestId' element={<AccessRequest />} /> 
            <Route path='template/:templateId' element={<Template />} /> 
            <Route path='consultation/note/:consultationNoteId' element={<ConsultationNote />} />
            <Route path='consultation/note/mic/:consultationNoteId/:securityPin' element={<NoteMicrophone />} />
          </Routes>
        </Container>
      </MuiThemeProvider>
    </ThemeProvider>
  );
}

export default App;