

const attributesList = [
    'patientId', 
    'firstName', 
    'lastName',
    'gender',
    'dateOfBirth', 
    'healthCardNumber',
    'phone',
    'address', 
    'email', 
]

export default attributesList; 