import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// Components 

import Box from '@mui/material/Box';
import { getConsultationNote } from '../../routes/doctor/consultation';
import { setConsultationNoteId, setSecurityPin } from '../../redux/features/doctor/Dashboard/consultation/noteMicrophone';
import Header from './header/Header';
import Footer from './footer/Footer';
import OptionsList from './footer/lists/OptionsList';
import SettingsList from './footer/lists/SettingsList';

// -- 

const Container: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { consultationNoteId } = useParams() as any; 
    const { securityPin } = useParams() as any; 

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const colors = useAppSelector(state => state.theme.colors); 
    const accountInfo = useAppSelector(state => state.meta.accountInfo); 

    const isOptionsListOpened = useAppSelector(state => state.noteMicrophone.isOptionsListOpened); 
    const isSettingsListOpened = useAppSelector(state => state.noteMicrophone.isSettingsListOpened);

    const [timeLeft, setTimeLeft] = useState(2); 

    // -- 

    useEffect(() => { 

        if (consultationNoteId) { 

            dispatch(setConsultationNoteId(consultationNoteId)); 

        }; 

    },[consultationNoteId]); 

    // -- 

    useEffect(() => { 

      if (securityPin) { 

        dispatch(setSecurityPin(securityPin)); 

      }; 

    },[securityPin]); 

    // -- 

    return (

      <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '100vw', 
            height: '100vh', 
        }}
      >

        <Header /> 

        <Footer /> 

        {isOptionsListOpened && <OptionsList />} 

        {isSettingsListOpened && <SettingsList />} 

      </Box>

  );

};

export default Container;