import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';

// Components
import NavBar from '../../general/navBar/NavBar';
import Settings from '../navigation/navBar/Settings';
import Sidebar from '../../general/navigation/Sidebar';

// Redux
import { setDashboardSection, setSideBarMenu } from '../../../redux/features/general/navigation';

// Navigation menus
import menuItems from '../../../util/navigation/sidebarMenu/doctor';
import doctorDashboardMenu from '../../../util/navigation/dashboard/doctor';

// Dashboard Interfaces 

import FileExplorer from './fileExplorer/FileExplorer';
import Templates from './templates/Templates';
import Consultation from './consultation/Consultation';

import PatientsInterface from './PatientsInterface';
import InsurersInterface from './InsurersInterface';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ImageViewer from './fileExplorer/actions/imageViewer/ImageViewer';

const Container: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();  
  
  const { currentSection } = useParams() as any; 

  const colors = useAppSelector(state => state.theme.colors); 
  const section = useAppSelector((state) => state.navigation.section);
  const consultationId = useAppSelector((state) => state.consultation.consultationId);
  const mode = useAppSelector(state => state.fileExplorer.mode); 
  const isImageViewerOpened = useAppSelector(state => state.imageViewer.isImageViewerOpened); 

  const container = document.getElementById("mainContainer") as any; 

  container?.addEventListener("contextmenu", (e: any) => {e.preventDefault()});

  // -- 

  window.onbeforeunload = function (e: any) {

    if ((consultationId)) { 

      // For IE and Firefox

      if (e) {
        e.returnValue;
      }; 
    
      // For Safari
      return e.returnValue;

    };

  };

  // -- 

  useEffect(() => {
    
    dispatch(setSideBarMenu(menuItems));

  }, []);

  // -- 

  useEffect(() => { 

    if (currentSection) {

      dispatch(setDashboardSection(currentSection)); 

    }; 

  },[currentSection]); 

  // -- 

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        id='mainContainer'
        component="main"
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          left: 0,
          zIndex: 0,
          overflowX: 'hidden',
          position: 'absolute',
          color: colors?.text,
        }}
      >
        <NavBar>
          <Settings />
        </NavBar>

        <Sidebar />

        {/* SideBar sections */}

        {section === 'documents' && <FileExplorer />}
        {section === 'patients' && <PatientsInterface />}
        {section === 'insurers' && <InsurersInterface />}
        {section === 'templates' && <Templates />}
        {section === 'consultation' && <Consultation />}

        {isImageViewerOpened && <ImageViewer />}  

      </Box>
      
    </React.Fragment>
  );
};

export default Container;