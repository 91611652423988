import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Microphone from './items/Microphone';
import Options from './items/Options';
import Settings from './items/Settings';

const Footer: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          justifyContent: 'space-between', 
          width: '70vw',
          height: '25vh',
          position: 'absolute',
          bottom: 0,
          overflow: 'hidden',  
      }}>

        <Options /> 

        <Microphone /> 

        <Settings /> 

      </Box>

  );

};

export default Footer;