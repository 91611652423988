import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import Legend from './Legend';
import List from './List';

const Notes: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

        <Box             
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                width: '100%',
                height: 'auto', 
        }}>

            <Legend /> 

            <List /> 
        
        </Box>

    );

};

export default Notes;