import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

// Redux 

import { setOpenPopup } from '../../../../../../redux/features/general/popup';

const Warning: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    const erroredFields = useAppSelector(state => state.createPatient.erroredFields); 

    const [showWarning, setShowWarning] = useState(false); 

    // -- 


    useEffect(() => { 

        if (erroredFields.length > 0) { 

            setShowWarning(false); 

            erroredFields.map((field: any) => { 

                if (field.error === 'requiredField') { 
    
                    setShowWarning(true); 
    
                }; 
    
            });

        } else { 

            setShowWarning(false); 

        }; 

    },[erroredFields]); 


    // -- 

    return (

    <>

        { showWarning && 

            <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '65%', 
                color: colors?.createPatientError,
                fontSize: 12, 
                marginBottom: 3,
                textAlign: 'center',
            }}>{t('requiredFieldsWarning')}

        </Box>}

    </>

  );

};

export default Warning;