import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Text from './Text';

// -- 

const Document: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const [height, setHeight] = useState(0); 
    const [width, setWidth] = useState(0); 

    const consultationNoteRef = useRef() as any;

    const imageComponent = document?.getElementById('consultationNote-img'); 
    const textFieldComponent = document?.getElementById("consultationNote-textField") as any; 

    // -- 

    const handleDocument = () => { 

      textFieldComponent.focus(); 

    }; 

    // -- 

    return (

        <Box
            id='consultationNote'
            ref={consultationNoteRef}
            sx={{ 
              display: 'flex',
              flexDirection: 'column', 
              alignItems: 'center', 
              width: '100vw', 
              height: '90vh',
              borderRadius: 3,
              position: 'absolute',
              right: 0,
              bottom: 0, 
              overflow: 'scroll',
              scrollbarWidth: '1',  
            }}
      >

        <Box
          onClick={handleDocument}
          id='consultationNote-pdf'
          sx={{ 
              borderWidth: 'none', 
              border: 0,
              marginTop: '3vh', 
              cursor: 'default',
              backgroundColor: 'rgb(255, 255, 255)',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
              height: '877px', 
              width: '620px',
              position: 'absolute', 
          }}
        >
          <Text width={width} height={height} /> 

        </Box>

      </Box>

  );

};

export default Document;