import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 
import getPdfUrl from '../../../util/template/getPdfUrl';

// ---------------
 
interface TemplateState {
    templateId: string, 
    section: string, 
    templateMode: string, 
    doc: any, 
    form: any, 
    fields: any[], 
    patientsList: any[], 
    textFields: any[], 
    selectedTextField: any, 
    selectedTextFieldId: string, 
    selectedTextFieldIndex: number, 
    filteredPatientsList: any[], 
    patientSearchQuery: string,
    selectedPatient: any, 
    currentPage: any, 
    currentTextFieldName: string, 
    currentTextField: any, 
    currentTextFieldPosition: any, 
    currentTextFieldMove: number, 
    initialRendering: boolean, 
    currentPdfBytes: any, 
    currentPdfUrl: string, 
    numberOfPages: number, 
    currentPageIndex: 0, 
    currentPageHeight: number, 
    currentPageWidth: number, 
    fontFamily: any, 
    fontSize: number, 
    fontStyle: string, 
    fontWeight: string,
    textDecoration: string, 
    textColor: string, 
    textAlign: string, 
    textDegree: number,
    updatePdf: boolean, 
    newTextFieldPosition: any, 
    newTextFieldTitle: string, 
    newTextFieldAttribute: string, 
    pendingTextFields: any[], 
    removedTextFields: any[], 
    isAutoCompleteProcessing: boolean, 
    isDocumentAutoCompleted: boolean, 
    isRefreshFieldsList: boolean, 
    isAttributesDropdownOpened: boolean, 
    isCreatingTextField: boolean, 
    isModificationLoading: boolean,
    isTemplateModified: boolean, 

  };
  
  // ---------------
  
  const initialState: TemplateState = {
    templateId: '', 
    section: 'forms', 
    templateMode: '', 
    doc: null, 
    form: null, 
    fields: [], 
    patientsList: [], 
    filteredPatientsList: [], 
    patientSearchQuery: '', 
    selectedPatient: null, 
    currentPage: null, 
    textFields: [], 
    selectedTextField: null, 
    selectedTextFieldIndex: -1, 
    selectedTextFieldId: '', 
    currentTextFieldName: '', 
    currentTextField: null, 
    currentTextFieldPosition: null, 
    currentTextFieldMove: 10, 
    initialRendering: true, 
    currentPdfBytes: null,
    currentPdfUrl: '',
    numberOfPages: 0, 
    currentPageIndex: 0, 
    currentPageHeight: 0,
    currentPageWidth: 0, 
    fontFamily: null, 
    fontSize: 16, 
    fontStyle: 'normal', 
    fontWeight: 'normal',
    textDecoration: '', // 'underline' for to underline a text. 
    textColor: 'rgb(20, 20, 20)',
    textAlign: 'left', 
    textDegree: 0, // degrees(-45), import { degree } from pdf-lib; 
    updatePdf: false, 
    newTextFieldPosition: null, 
    newTextFieldTitle: '', 
    newTextFieldAttribute: '', 
    pendingTextFields: [], 
    removedTextFields: [],
    isAutoCompleteProcessing: false, 
    isDocumentAutoCompleted: false, // to specify the saving mode. 
    isRefreshFieldsList: false, 
    isAttributesDropdownOpened: false, 
    isCreatingTextField: false, 
    isModificationLoading: false, 
    isTemplateModified: false, 
  };

  interface UpdateTextFieldProps { 
    selectedTextFieldId: string, 
    updatedTextField: any,
    index: number, 
  }; 
  
  // ---------------
  
  export const templateSlice = createSlice({
  
    name: 'template',
  
    initialState,
  
    reducers: {
      
      setTemplateId: (state, action: PayloadAction<any>) => {
  
        state.templateId = action.payload; 

      },    
      setSection: (state, action: PayloadAction<string>) => {
  
        state.section = action.payload; 

      },
      setTemplateMode: (state, action: PayloadAction<string>) => {
  
        state.templateMode = action.payload; 

      },
      setDoc: (state, action: PayloadAction<any>) => {
  
        state.doc = action.payload; 

      },
      setForm: (state, action: PayloadAction<any>) => {
  
        state.form = action.payload; 

      },
      setFields: (state, action: PayloadAction<any>) => {
  
        state.fields = action.payload; 

        
      },
      setPatientsList: (state, action: PayloadAction<any>) => {
  
        state.patientsList = action.payload; 

      },
      addTextField: (state, action: PayloadAction<any>) => {
  
        state.textFields.push(action.payload);
        
        state.isTemplateModified = true;  

      },
      deleteTextField: (state, action: PayloadAction<any>) => {

        const { textField, index } = action.payload; 

        const newTextFieldObject = { 
          textFieldId: textField._id, 
          x: 0,
          y: 0,
          width: 0,
          height: 0, 
          description: '',
          attribute: '',
          style: null, 
          textField: null,
          isRemoved: true, // new
        }; 

        state.textFields.splice(index, 1, newTextFieldObject); 

        state.isTemplateModified = true;  

      },
      setTextFields: (state, action: PayloadAction<any>) => {

        state.textFields = action.payload; 

      },
      updateTextFields: (state, action: PayloadAction<UpdateTextFieldProps>) => {

        const { selectedTextFieldId, updatedTextField, index } = action.payload; 

        state.textFields.splice(index, 1, updatedTextField); 

        state.isTemplateModified = true;  

      },
      setSelectedTextField: (state, action: PayloadAction<any>) => {

        if (action.payload) { 

          const { textField, index } = action.payload; 

          state.selectedTextFieldId = textField.textFieldId ? textField.textFieldId : textField._id; 
          state.selectedTextField = textField; 
          state.selectedTextFieldIndex = index; 

          state.textAlign = textField.style.textAlign ? textField.style.textAlign : state.textAlign;
          state.textColor = textField.style.textColor ? textField.style.textColor : state.textColor;
          state.fontStyle = textField.style.fontStyle ? textField.style.fontStyle : state.fontStyle;
          state.fontWeight = textField.style.fontWeight ? textField.style.fontWeight : state.fontWeight;
          state.textDecoration = textField.style.textDecoration ? textField.style.textDecoration : state.textDecoration;
          state.textDegree = textField.style.textDegree ? textField.style.textDegree : state.textDegree;
          state.fontSize = textField.style.fontSize ? textField.style.fontSize : state.fontSize;
          state.fontFamily = textField.style.fontFamily ? textField.style.fontFamily : state.fontFamily;

        } else { 

          state.selectedTextFieldId = ''; 
          state.selectedTextField = null; 
          state.selectedTextFieldIndex = -1; 

        }; 
  
      }, 
      setSelectedTextFieldId: (state, action: PayloadAction<string>) => {

        if (action.payload) { 

          state.selectedTextFieldId = action.payload; 

        } else { 

          state.selectedTextFieldId = ''; 
          state.selectedTextField = null;
          state.selectedTextFieldIndex = -1; 

        };

      },
      setFilteredPatientsList: (state, action: PayloadAction<any>) => {
  
        state.filteredPatientsList = action.payload; 

      },
      setPatientSearchQuery: (state, action: PayloadAction<string>) => {
  
        state.patientSearchQuery = action.payload; 

      },
      setSelectedPatient: (state, action: PayloadAction<any>) => {
  
        state.selectedPatient = action.payload; 

      },
      setCurrentPage: (state, action: PayloadAction<any>) => {
  
        state.currentPage = action.payload; 

      },
      setCurrentTextFieldName: (state, action: PayloadAction<string>) => {
  
        state.currentTextFieldName = action.payload; 

      },
      setCurrentTextField: (state, action: PayloadAction<any>) => {
  
        state.currentTextField = action.payload; 

      },
      setCurrentTextFieldPosition: (state, action: PayloadAction<any>) => {
  
        state.currentTextFieldPosition = action.payload; 

      },
      setCurrentTextFieldMove: (state, action: PayloadAction<number>) => {
  
        state.currentTextFieldMove = action.payload; 

      },
      setCurrentPdfBytes: (state, action: PayloadAction<any>) => {

        if (state.currentPdfBytes) { 
          state.isTemplateModified = true; 
        }; 
  
        state.currentPdfBytes = action.payload; 
        state.currentPdfUrl = getPdfUrl(action.payload); 
        state.isModificationLoading = false;  

      },
      setCurrentPdfUrl: (state, action: PayloadAction<string>) => {
  
        state.currentPdfUrl = action.payload;
        state.isModificationLoading = false; 

      },
      setNumberOfPages: (state, action: PayloadAction<number>) => {
  
        state.numberOfPages = action.payload; 

      },
      setCurrentPageIndex: (state, action: PayloadAction<any>) => {
  
        state.currentPageIndex = action.payload; 

      },
      setCurrentPageHeight: (state, action: PayloadAction<number>) => {
  
        state.currentPageHeight = action.payload; 

      },
      setCurrentPageWidth: (state, action: PayloadAction<number>) => {
  
        state.currentPageWidth = action.payload; 

      },
      setFontFamily: (state, action: PayloadAction<any>) => {
  
        state.fontFamily = action.payload; 

      }, 
      setFontSize: (state, action: PayloadAction<number>) => {
  
        state.fontSize = action.payload; 
        state.updatePdf = true; 

      }, 
      setFontStyle: (state, action: PayloadAction<string>) => {
  
        state.fontStyle = action.payload; 

      }, 
      setFontWeight: (state, action: PayloadAction<string>) => {
  
        state.fontWeight = action.payload; 

      }, 
      setTextDecoration: (state, action: PayloadAction<string>) => {
  
        state.textDecoration = action.payload; 

      }, 
      setTextColor: (state, action: PayloadAction<string>) => {
  
        state.textColor = action.payload; 

      }, 
      setTextDegree: (state, action: PayloadAction<number>) => {
  
        state.textDegree = action.payload; 

      }, 
      setTextAlign: (state, action: PayloadAction<string>) => {
  
        state.textAlign = action.payload; 

      }, 
      setUpdatePdf: (state, action: PayloadAction<boolean>) => {
  
        state.updatePdf = action.payload; 

      },  
      setNewTextFieldPosition: (state, action: PayloadAction<any>) => {
  
        state.newTextFieldPosition = action.payload; 

      },  
      setNewTextFieldTitle: (state, action: PayloadAction<string>) => {
  
        state.newTextFieldTitle = action.payload; 

      }, 
      setNewTextFieldAttribute: (state, action: PayloadAction<string>) => {
  
        state.newTextFieldAttribute = action.payload; 

      }, 
      setPendingTextFields: (state, action: PayloadAction<any>) => {
  
        state.pendingTextFields = action.payload; 

      }, 
      addPendingTextField: (state, action: PayloadAction<any>) => {
  
        state.pendingTextFields.push(action.payload); 

      }, 
      setRemovedTextFields: (state, action: PayloadAction<any>) => {
  
        state.removedTextFields = action.payload; 

      }, 
      addRemovedTextField: (state, action: PayloadAction<any>) => {
  
        state.removedTextFields.push(action.payload); 

      }, 
      setIsAutoCompleteProcessing: (state, action: PayloadAction<boolean>) => {
  
        state.isAutoCompleteProcessing = action.payload; 

      }, 
      setIsDocumentAutoCompleted: (state, action: PayloadAction<boolean>) => {
  
        state.isDocumentAutoCompleted = action.payload; 

      }, 
      setIsRefreshFieldsList: (state, action: PayloadAction<boolean>) => {
  
        state.isRefreshFieldsList = action.payload; 

      },     
      setIsAttributesDropdownOpened: (state, action: PayloadAction<boolean>) => {
  
        state.isAttributesDropdownOpened = action.payload; 

      },   
      setIsCreatingTextField: (state, action: PayloadAction<boolean>) => {
  
        state.isCreatingTextField = action.payload; 

      },  
      setIsTemplateModified: (state, action: PayloadAction<boolean>) => {
  
        state.isTemplateModified = action.payload; 

      },  
      setIsModificationLoading: (state, action: PayloadAction<boolean>) => {
  
        state.isModificationLoading = action.payload; 

      }, 
      setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
        state.initialRendering = action.payload; 
  
      }, 
      resetTemplate: (state) => { 
          
          state.templateId = ''; 
          state.section = 'forms'; 
          state.templateMode = ''; 
          state.doc = null; 
          state.form = null;
          state.fields = []; 
          state.patientsList = []; 
          state.textFields = []; 
          state.selectedTextField = null;
          state.selectedTextFieldIndex = -1; 
          state.selectedTextFieldId = ''; 
          state.filteredPatientsList = []; 
          state.patientSearchQuery = ''; 
          state.selectedPatient = null; 
          state.currentPage = null; 
          state.currentTextFieldName = ''; 
          state.currentTextField = null; 
          state.currentTextFieldPosition = null; 
          state.currentTextFieldMove = 10; 
          state.initialRendering = true; 
          state.currentPdfUrl = ''; 
          state.currentPdfBytes = null; 
          state.numberOfPages = 0; 
          state.currentPageIndex = 0; 
          state.currentPageHeight = 0; 
          state.currentPageWidth = 0; 
          state.fontFamily = null; 
          state.fontSize = 16; 
          state.fontStyle = 'normal'; 
          state.fontWeight = 'normal'; 
          state.textDecoration = ''; 
          state.textColor = 'rgb(20, 20, 20)'; 
          state.textAlign = 'textAlign'; 
          state.textDegree = 0; 
          state.updatePdf = false; 
          state.newTextFieldPosition = null; 
          state.newTextFieldTitle = ''; 
          state.newTextFieldAttribute = '';
          state.pendingTextFields = []; 
          state.removedTextFields = []; 
          state.isAutoCompleteProcessing = false; 
          state.isDocumentAutoCompleted = false; 
          state.isRefreshFieldsList = false; 
          state.isAttributesDropdownOpened = false; 
          state.isCreatingTextField = false; 
          state.isModificationLoading = false; 
          state.isTemplateModified = false; 
      } 
    },
  
  }); 
  
  // ---------------
  
  export const { 
        setTemplateId, 
        setSection, 
        setTemplateMode, 
        setDoc, 
        setForm, 
        setFields, 
        setPatientsList, 
        setTextFields,
        addTextField,
        updateTextFields,
        setSelectedTextField, 
        setSelectedTextFieldId, 
        setFilteredPatientsList, 
        setPatientSearchQuery, 
        setSelectedPatient, 
        setCurrentPage, 
        setCurrentTextFieldName, 
        setCurrentTextField, 
        setCurrentTextFieldPosition,
        setCurrentTextFieldMove, 
        setCurrentPdfBytes,
        setCurrentPdfUrl, 
        setNumberOfPages, 
        setCurrentPageIndex,
        setCurrentPageHeight, 
        setCurrentPageWidth, 
        setFontFamily, 
        setFontSize, 
        setFontStyle, 
        setFontWeight, 
        setTextDecoration,
        setTextColor,  
        setTextAlign, 
        setTextDegree, 
        setUpdatePdf, 
        setNewTextFieldPosition, 
        setNewTextFieldTitle,
        setNewTextFieldAttribute, 
        setPendingTextFields, 
        addPendingTextField, 
        setRemovedTextFields, 
        addRemovedTextField, 
        deleteTextField, 
        setIsAutoCompleteProcessing, 
        setIsDocumentAutoCompleted, 
        setIsRefreshFieldsList, 
        setIsAttributesDropdownOpened, 
        setIsCreatingTextField, 
        setIsTemplateModified,
        setIsModificationLoading, 
        setInitialRendering, 
        resetTemplate
   } = templateSlice.actions;
  
  export default templateSlice.reducer;