import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components

import FolderIcon from '@mui/icons-material/Folder';
import { getDoctorName } from '../../../../../../../routes/doctor/patients';
import { setSection } from '../../../../../../../redux/features/doctor/Dashboard/consultation/savedConsultation';

interface Props { 
    children: React.ReactNode; 
    category: string; 
    numberOfItems: number | 0; 
}; 

const CategoryRow: React.FC<Props> = ({ children, category, numberOfItems }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);
    
    const [formattedNumberOfItems, setFormattedNumberOfItems] = useState(''); 

    // -- 

    const handleSelection = (e: any) => { 

        if (e.detail === 2) { 

            dispatch(setSection(category)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (numberOfItems === 1) { 

            setFormattedNumberOfItems(`${numberOfItems} Item`); 

        } else if (numberOfItems > 1) { 

            setFormattedNumberOfItems(`${numberOfItems} Items`); 

        }; 

    },[numberOfItems]); 

    // -- 

    return (

        <Box 
            onClick={(e) => handleSelection(e)}
            sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            fontSize: 12, 
            height: 40,
            cursor: 'pointer',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: colors?.sectionHover }
        }}>

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                minWidth: '10%', 
                marginLeft: '2%',  
            }}>

                {children}

            </div>
            
            <div style={{ 
                width: '50%',
                color: colors?.text, 
                textAlign: 'left',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }}>{t(category)}</div>

            <div style={{ 
                width: '20%',
                color: colors?.text, 
                textAlign: 'left',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }}>{formattedNumberOfItems}</div>

        </Box>

    );
};

export default CategoryRow;