import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { serveConsultationNote } from '../../../../../../routes/doctor/consultation';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { serveFile } from '../../../../../../routes/doctor/files';

const AttachedFileViewer: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const { t } = useTranslation(); 

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');
    const leftOffset = isSmallScreen ? '60px' : '5vw';

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const colors = useAppSelector(state => state.theme.colors);

    const selectedAttachedFile = useAppSelector(state => state.savedConsultation.selectedAttachedFile) as any; 

    const [fileUrl, setFileUrl] = useState(''); 

    // -- 

    const handleConsulationNote = async () => { 
        
        const res = await serveFile(selectedAttachedFile._id) as any; 

        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });

        setFileUrl(URL.createObjectURL(fileBlob));  

    }; 

    // -- 

    useEffect(() => { 

        if (selectedAttachedFile) { 

            handleConsulationNote(); 

        }; 

    },[selectedAttachedFile]); 

    // --

    return (

        <Box
            sx={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: '80%',
                height: '95%', 
                position: 'absolute'
            }}>

                { fileUrl && 
                    <iframe
                        ref={iframeRef}
                        src={fileUrl}
                        style={{ 
                            width: '100%', 
                            height: '100%',
                        }}
                        title="PDF Viewer"
                /> }
                        
        </Box>

    );

};

export default AttachedFileViewer;