import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Directory from './Directory';
import SearchBar from './SearchBar';
import HeaderActions from './HeaderActions';

// Components 

const Header: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const mode = useAppSelector(state => state.fileExplorer.mode); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '15%', 
            flexShrink: 0, 
        }}>

            <HeaderActions /> 

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                height: '30%', 
                flexShrink: 0,
            }}>

                <Directory /> 

                <SearchBar /> 

            </Box>
            
        </Box>

    );

};

export default Header;