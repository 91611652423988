import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { getMetaPatientInfo } from '../../../../../../routes/doctor/patients';

// Components 

import PersonIcon from '@mui/icons-material/Person';

const Directory: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const mode = useAppSelector(state => state.fileExplorer.mode); 

    const consultationId = useAppSelector(state => state.savedConsultation.consultationId); 
    const patientId = useAppSelector(state => state.savedConsultation.patientId); 
    const accountInfo = useAppSelector(state => state.meta.accountInfo); 

    const [patientFullName, setPatientFullName] = useState(''); 

    // -- 

    const handlePatientFullName = async () => { 

        const res = await getMetaPatientInfo(patientId, accountInfo?._id); 

        if (res?.data.status === 200) { 

            setPatientFullName(res.data.metaPatient.fullName); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (patientId) { 

            handlePatientFullName(); 

        }; 

    },[patientId]); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '75%', 
            scrollbarWidth: 'none', 
            marginLeft: 15, 
            fontSize: 15,
        }}>
            
            <PersonIcon sx={{ color: colors?.patientsFolderIcon, fontSize: 25, marginLeft: 2, marginRight: 1 }} />

            {patientFullName}
            
        </Box>
    );

};

export default Directory;