import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setFontSize, setIsRefreshFieldsList, setSelectedTextField, updateTextFields } from '../../../../redux/features/template/template';

// Components 

import Box from '@mui/material/Box';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { updateTemplateTextField } from '../../../../routes/doctor/templates';

const FontSize: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const fontSize = useAppSelector(state => state.template.fontSize); 
    const selectedTextFieldId = useAppSelector(state => state.template.selectedTextFieldId); 
    const selectedTextFieldIndex = useAppSelector(state => state.template.selectedTextFieldIndex);

    const [fontSizeInput, setFontSizeInput] = useState(''); 

    // -- 

    const decreaseFontSize = () => { 

        if (fontSize >= 7) { 

            dispatch(setFontSize(fontSize - 1)); 

            handleFontSizeUpdate(fontSize - 1); 

        };

    };    

    // -- 

    const increaseFontSize = () => { 

        if (fontSize <= 199) { 

            dispatch(setFontSize(fontSize + 1));

            handleFontSizeUpdate(fontSize + 1); 

        };

    }; 

    // -- 

    const handleFontSizeUpdate = async (fontSize?: number) => { 

        const style = { 
            fontSize: fontSize
        };

        const content = { 
            textFieldId: selectedTextFieldId,
            style: style, 
        }; 
    
        const res = await updateTemplateTextField(content) as any; 
    
        if (res.data.status == 200) {
    
            dispatch(updateTextFields({ 
                selectedTextFieldId: selectedTextFieldId,
                updatedTextField: res.data.templateTextField,
                index: selectedTextFieldIndex
            })); 
    
            dispatch(setSelectedTextField({ textField: res.data.templateTextField, index: selectedTextFieldIndex }));
    
        }; 

    };
    


    // -- 

    const handleUpdatedFontSize = () => { 

        const regex = new RegExp('^[0-9]+$');

        if (fontSizeInput) { 

            if (regex.test(fontSizeInput)) { 

                if ((Number(fontSizeInput) > 6) && (Number(fontSizeInput) < 200) && (!fontSizeInput.startsWith('0'))) { 
    
                    dispatch(setFontSize(Number(fontSizeInput))); 
                }; 
    
            }; 

        };

    }; 

    // -- 

    // -- 

    const handleEnterKey = async (e: any) => { 
        
        if (e.key === 'Enter' && e.ctrlKey) { 

            handleUpdatedFontSize(); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (fontSize) { 

            setFontSizeInput(String(fontSize)); 

        }; 

    },[fontSize]); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          height: '100%',
          color: colors?.text,
          marginLeft: 2,  
      }}>

        <Box
            onClick={decreaseFontSize} 
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30,
                height: 30, 
                borderRadius: 1, 
                cursor: 'pointer', 
                '&:hover': { backgroundColor: colors?.templateFontSizeFunction}
            }}
        >
            <RemoveIcon 
                sx={{ 
                    width: 20,
                    '&:hover': { color: colors?.highlightedText }
            }} /> 

        </Box>

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30,
                height: 30,  
                backgroundColor: colors?.templateFontSizeBackground,
                borderRadius: 1, 
                marginLeft: 1,
                marginRight: 1, 
                fontSize: 12
            }}
        >{fontSize}
        {/* <TextField 
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30, 
                backgroundColor: 'blue',
                textAlign: 'center'
             }} 
            onChange={(e) => setFontSizeInput(e.target.value)}
            id="standard-basic" 
            value={fontSizeInput}
            InputProps={{
                style: {
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly',
                    fontSize: 12, 
                    width: '100%', 
                    textAlign: 'center'
                },
                autoComplete: 'off', 
                disableUnderline: true, 
            }}
            variant="standard" 
            onKeyDown={(e) => handleEnterKey(e)}
        /> */}
        </Box>

        <Box
            onClick={increaseFontSize} 
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30,
                height: 30, 
                borderRadius: 1, 
                cursor: 'pointer',
                '&:hover': { backgroundColor: colors?.templateFontSizeFunction}
            }}
        >

        <AddIcon 
            sx={{
                width: 20, 
                '&:hover': { color: colors?.highlightedText } 
            }} /> 

        </Box>



      </Box>

  );

};

export default FontSize;