import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Legend from './Legend';
import List from './List';

// Components

const Files: React.FC = () => {

    return (

        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%',
                height: '75%', 
            }}>

            <Legend /> 

            <List /> 
            
        </Box>

    );

};

export default Files;