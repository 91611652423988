import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Components 

import Box from '@mui/material/Box';
import PatientDataList from './patientData/PatientDataList';
import Notes from './notes/Notes';
import Directives from './directives/Directives';
import Overview from './overview/Overview';
import Settings from './settings/Settings';
import PatientLogsList from './patientLogs/PatientLogsList';
import NotificationsList from './notifications/NotificationsList';
import AccessRequestsList from './accessRequests/AccessRequestsList';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const Info: React.FC<Props> = ({ children, isPortraitMode }) => {

  const dispatch = useAppDispatch();

  const colors = useAppSelector(state => state.theme.colors);
  const infoSection = useAppSelector(state => state.patient.infoSection); 

  return (

    <Box
        sx={{
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        width: '70%',
        height: '95%', 
        bottom: 0,
        borderRadius: 5,
        backgroundColor: colors?.container,
        transition: 'left 0.3s',    
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}
    >

        { infoSection === 'overview' && <Overview />}
        { infoSection === 'info' && <PatientDataList />}
        { infoSection === 'notes' && <Notes />}
        { infoSection === 'directives' && <Directives />}
        { infoSection === 'settings' && <Settings />}
        { infoSection === 'patientLogs' && <PatientLogsList />}
        { infoSection === 'notifications' && <NotificationsList />}
        { infoSection === 'accessRequests' && <AccessRequestsList />}

    </Box>

  );
};

export default Info;