import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setFontSize, setFontStyle, setFontWeight, setIsRefreshFieldsList, setSelectedTextField, setTextDecoration, updateTextFields } from '../../../../redux/features/template/template';

// Components 

import Box from '@mui/material/Box';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import { updateTemplateTextField } from '../../../../routes/doctor/templates';

const TextSyle: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const fontWeight = useAppSelector(state => state.template.fontWeight); 
    const fontStyle = useAppSelector(state => state.template.fontStyle); 
    const textDecoration = useAppSelector(state => state.template.textDecoration); 
    const textColor = useAppSelector(state => state.template.textColor); 
    const selectedTextFieldId = useAppSelector(state => state.template.selectedTextFieldId); 
    const selectedTextFieldIndex = useAppSelector(state => state.template.selectedTextFieldIndex);


    // -- 

    const handleTextStyle = (type: string) => { 

        let style = {} as any; 

        if (type === 'fontWeight') { 

            fontWeight === 'normal' ? dispatch(setFontWeight('bold')) : dispatch(setFontWeight('normal'));
            fontWeight === 'normal' ? style.fontWeight = 'bold' : style.fontWeight = 'normal';

        } else if (type === 'fontStyle') { 

            fontStyle === 'normal' ? dispatch(setFontStyle('italic')) : dispatch(setFontStyle('normal')); 
            fontStyle === 'normal' ? style.fontStyle = 'italic' : style.fontStyle = 'normal';

        } else if (type === 'textDecoration') { 

            textDecoration === '' ? dispatch(setTextDecoration('underline')) : dispatch(setTextDecoration('')); 
            textDecoration === '' ? style.textDecoration = 'underline' : style.textDecoration = 'none';

        }; 

        if (selectedTextFieldId) { 

            handleTextFieldUpdate(style); 

        }; 

    };

    // -- 

    const handleTextFieldUpdate = async (style?: any) => { 

        const content = { 
            textFieldId: selectedTextFieldId,
            style: style, 
    
        }; 
    
        const res = await updateTemplateTextField(content) as any; 
    
        if (res.data.status == 200) {
    
            dispatch(updateTextFields({ 
                selectedTextFieldId: selectedTextFieldId,
                updatedTextField: res.data.templateTextField,
                index: selectedTextFieldIndex 
            })); 

            dispatch(setSelectedTextField({ textField: res.data.templateTextField, index: selectedTextFieldIndex }));
    
        }; 

    };
    
    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          height: '100%',
          marginLeft: 5,
          color: colors?.text 
      }}>

        <Box
            onClick={() => handleTextStyle('fontWeight')}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30,
                height: 30,  
                backgroundColor: (fontWeight === 'bold') ? colors?.templateToolEnabled : colors?.templateToolDisabled,
                borderRadius: 1, 
                marginLeft: 1,
                fontSize: 12,
                cursor: 'pointer',
                '&:hover': { backgroundColor: (fontWeight === 'bold') ? colors?.templateToolEnabledHover : colors?.templateToolDisabledHover }
            }}
        >
            <FormatBoldIcon sx={{ fontSize: 14, color: colors?.text }} />
        </Box>

        <Box
            onClick={() => handleTextStyle('fontStyle')}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30,
                height: 30,  
                backgroundColor: (fontStyle === 'italic') ? colors?.templateToolEnabled : colors?.templateToolDisabled,
                borderRadius: 1, 
                marginLeft: 1, 
                fontSize: 12,
                cursor: 'pointer',
                '&:hover': { backgroundColor: (fontStyle === 'italic') ? colors?.templateToolEnabledHover : colors?.templateToolDisabledHover }
            }}
        >
            <FormatItalicIcon sx={{ fontSize: 14, color: colors?.text }} />
        </Box>

        <Box
            onClick={() => handleTextStyle('textDecoration')}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30,
                height: 30,  
                backgroundColor: (textDecoration === 'underline') ? colors?.templateToolEnabled : colors?.templateToolDisabled,
                borderRadius: 1, 
                marginLeft: 1,
                fontSize: 12,
                cursor: 'pointer',
                '&:hover': { backgroundColor: (textDecoration === 'underline') ? colors?.templateToolEnabledHover : colors?.templateToolDisabledHover }
            }}
        >
            <FormatUnderlinedIcon sx={{ fontSize: 14, color: colors?.text }} />
        </Box>

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 30,
                height: 30,  
                backgroundColor: colors?.templateToolDisabled,
                borderRadius: 1, 
                marginLeft: 1,
                fontSize: 12,
                cursor: 'pointer',
                '&:hover': { backgroundColor: colors?.templateToolDisabledHover }
            }}
        >
            <FormatColorTextIcon sx={{ fontSize: 14, color: colors?.text }} />
        </Box>

      </Box>

  );

};

export default TextSyle;