import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

// Components 

import Box from '@mui/material/Box';

// Icons 

interface Props { 
    children?: React.ReactNode; 
    description?: string; 
    isHeader?: boolean; 
}; 

const DetailRow: React.FC<Props> = ({ children, description, isHeader }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    // -- 
    
    return (


        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                paddingLeft: 2, 
                height: 30, 
                marginTop: 1,
                width: '100%',
                fontSize: 20
            }}
        >

            {children}

            { isHeader ? 
            
            <Box 
                sx={{ 
                    fontSize: 15, 
                    overflow: 'hidden', 
                    marginRight: 1 
            }}>{description}</Box>:
            <Box 
                sx={{ 
                    fontSize: 12, 
                    marginLeft: 1, 
                    overflow: 'hidden', 
                    marginRight: 1 
            }}>{description}</Box>}

        </Box>

    );

};

export default DetailRow;