import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

import { setConsultationId, setPatientId, setSection } from '../../../../../redux/features/doctor/Dashboard/consultation/consultation';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { createConsultation } from '../../../../../routes/doctor/consultation';

const Footer: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId); 
    const patientsList = useAppSelector(state => state.consultation.patientsList); 

    const [isLoading, setIsLoading] = useState(false); 
    
    // -- 

    const confirmSelection = async () => { 

        const res = await createConsultation(patientId) as any; 

        if (res?.data.status === 200) { 

            dispatch(setConsultationId(res.data.consultation._id)); 
            dispatch(setSection('dashboard')); 

        }; 

    }; 

    // -- 

    const handleUnSelection = () => { 

        dispatch(setPatientId('')); 

    }; 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            height: '10%',
            width: '100%',
            fontSize: 12,     
            color: colors?.text
        }}>

            { patientsList?.length > 0 && 
            
            <Box style={{ 
                display: 'flex', 
                flexDirection: 'row-reverse',
                alignItems: 'center',  
                width: '40%',
            }}>

                { patientId && <Button 
                    onClick={() => handleUnSelection()} 
                    sx={{ 
                        marginLeft: 1,
                        marginRight: 3,
                        color: colors?.closeButtonText, 
                        backgroundColor: colors?.closeButtonBackground,
                        borderColor: colors?.closeButtonBackground,
                        '&:hover': 
                        { 
                            backgroundColor: colors?.closeButtonBackground,
                            color: colors?.closeButtonText,
                            borderColor: colors?.closeButtonBackground
                        }
                    }}
                    variant="contained">{t('cancel')}
                </Button>}

            { patientId ? 
                <Button onClick={() => confirmSelection()} sx={{ marginLeft: 5 }} variant="contained">{t('start')}</Button>: 
                <Button 
                disabled={true}
                sx={{ 
                    backgroundColor: 'transparent',
                    borderColor: 'lightblue',
                    marginLeft: 5
                }}
                variant="outlined">{t('start')}</Button>}

                {isLoading && <CircularProgress />}
                    
            </Box>}

        </Box>
    );
};

export default Footer;