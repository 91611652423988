import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux 


// Components 

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { getDoctorName, getMetaPatientInfo, getPatient } from '../../../../../../routes/doctor/patients';
import { useTranslation } from 'react-i18next';
import DetailRow from './DetailRow';
import TollIcon from '@mui/icons-material/Toll';
import moment from 'moment';

const NoteDetails: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedConsultationNote = useAppSelector(state => state.savedConsultation.selectedConsultationNote); 
    const accountInfo = useAppSelector(state => state.meta.accountInfo); 

    const [patientName, setPatientName] = useState('');     
    const [doctorName, setDoctorName] = useState(''); 
    const [dateOfBirth, setDateOfBirth] = useState(''); 
    const [doctorCreationDate, setDoctorCreationDate] = useState(''); 
    const [logoUrl, setLogoUrl] = useState(require('../../../../../../assets/logos/emblem.png'));  

    const [isInfoSectionOpened, setIsInfoSectionOpened] = useState(true); 

    // -- 

    const openInfo = () => { 
        
        setIsInfoSectionOpened(true); 

    }; 

    // -- 

    const handleMetaPatientInfo = async () => { 

        const res = await getMetaPatientInfo(selectedConsultationNote.patientId, selectedConsultationNote.doctorId) as any; 
        
        if (res.data.status === 200) { 

            const splittedName = res.data.metaPatient.fullName.split(' ('); 

            setPatientName(splittedName[0]); 

            // const splittedDateOfBirth = res.data.metaPatient.fullName.split(' ('); 

            // const formattedDateOrBirth = splittedDateOfBirth[1].split(')'); 

            // setDateOfBirth(moment(formattedDateOrBirth[0]).format('LL')); 

        }; 

    }; 

    // -- 

    const handleDoctorInfo = async () => { 

        const res = await getDoctorName(selectedConsultationNote.doctorId) as any; 

        if (res.data.status === 200) { 

            setDoctorName(res.data.fullName); 
            setDoctorCreationDate(moment(res.data.createdAt).format('LL')); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (selectedConsultationNote) { 

            handleMetaPatientInfo(); 
            handleDoctorInfo(); 

        }; 

    },[selectedConsultationNote]); 

    // -- 

    return (

        <Box 
            sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            marginLeft: '1%',
            marginRight: '1%',
            width: '95%',
            height: 'auto',
            borderRadius: 3, 
            marginBottom: 1,
            paddingBottom: isInfoSectionOpened ? 1 : 0,  
            backgroundColor: colors?.formRow,
            color: colors?.text,
            '&:hover': { backgroundColor: colors?.formRowHover},
            transition: 'height 0.3s',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            textWrap: 'nowrap'
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    width: '100%', 
                    height: 40,
                }}
            >

                <Box
                    onClick={openInfo}
                    sx={{
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                        cursor: 'pointer',
                    }}
                >
                    <SpeakerNotesIcon sx={{ color: colors?.purple, fontSize: 25, marginLeft: 2 }} />

                    <Box sx={{ fontSize: 14, marginLeft: 2, overflow: 'hidden', marginRight: 1 }}>{t('noteDetails')}</Box>

                </Box>

                { isInfoSectionOpened ?
                
                <KeyboardArrowUpIcon 
                    onClick={() => setIsInfoSectionOpened(false)}
                    sx={{ 
                        fontSize: 18, 
                        color: 'rgb(100, 100, 100)',
                        marginRight: 1, 
                        cursor: 'pointer',
                        '&:hover': { color: colors?.text }
                    }} />: 
                <InfoOutlinedIcon 
                    onClick={openInfo}
                    sx={{ 
                        fontSize: 18, 
                        color: 'rgb(100, 100, 100)',
                        marginRight: 1, 
                        cursor: 'pointer',
                        '&:hover': { color: colors?.text }
                }} />}        
            
            </Box>


            { isInfoSectionOpened && 


            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    width: '100%', 
                    height: 'auto'
                }}
            >

                <DetailRow isHeader description={String(t('information'))} />

                <DetailRow description={moment(selectedConsultationNote?.createdAt).format('LL')}>
                    <EventNoteIcon sx={{ fontSize: 25, color: 'rgb(20,139,139)' }} />         
                </DetailRow>


                <DetailRow isHeader description={String(t('patient'))} />

                <DetailRow description={selectedConsultationNote?.patientId}>
                    <TollIcon sx={{ fontSize: 25, color: colors?.patientId }} />         
                </DetailRow>

                <DetailRow description={patientName}>
                    <AccountCircleIcon sx={{ fontSize: 25, color: colors?.templatePatientRowIcon }} />         
                </DetailRow>

                <DetailRow isHeader description={String(t('doctor'))} />

                <DetailRow description={doctorName}>
                    <AccountBoxIcon sx={{ fontSize: 25, color: colors?.phone }} />         
                </DetailRow>

                <DetailRow description={`Member since ${doctorCreationDate}`}>
                    <Box component="img" sx={{ height: 25, width: 25 }} alt="Solutions Medca Inc." src={logoUrl} />        
                </DetailRow>

            </Box>}

        </Box>
  );
};

export default NoteDetails;