import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setDashboardSection } from './../../../redux/features/general/navigation'; 

// Icons 

import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import Icon from '@mui/material/Icon';
import SidebarItem from './SidebarItem';

const Sidebar: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const drawerWidth = '5vw';

    const colors = useAppSelector(state => state.theme.colors);
    const section = useAppSelector(state => state.navigation.section);
    const sideBarMenu = useAppSelector(state => state.navigation.sideBarMenu);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        height: '95vh',
        flexShrink: 0,
        ['& .MuiDrawer-paper']: { 
          width: drawerWidth, 
          height: '95vh', 
          marginTop: '5vh', 
          boxSizing: 'border-box', 
          backgroundColor: colors?.sidebar, 
          color: colors?.highlightedText },
      }}
    >

      <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',         
            overflowY: 'auto', marginTop: 3, 
            scrollbarWidth: 'none',
            overflowX: 'hidden' }}>


      {sideBarMenu?.map((item, index) => (

        <SidebarItem item={item} index={index} key={item.key} hidden={item.hidden}/>
      
      ))}

      </Box>


    </Drawer>
  );
};

export default Sidebar;