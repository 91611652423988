import React, { useState, useEffect } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import SelectPatient from './selectPatient/SelectPatient';
import Dashboard from './dashboard/Dashboard';
import Popup from '../../../general/Popup';
import ExitPopup from './dashboard/header/ExitPopup';
import NoteViewer from './noteViewer/NoteViewer'; 
import FileViewer from './fileViewer/FileViewer';
import LoadingPopup from './dashboard/sections/files/LoadingPopup';
import ImageViewer from './imageViewer/ImageViewer';
import ImageLoadingPopup from './dashboard/sections/images/ImageLoadingPopup';
import ConfirmationPopup from './dashboard/header/ConfirmationPopup';

const Consultation: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const navigate = useNavigate(); 

  const { t } = useTranslation(); 

  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '5vw';

  const colors = useAppSelector(state => state.theme.colors);
  const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 
  const action = useAppSelector(state => state.popup.action); 
  const section = useAppSelector(state => state.consultation.section); 

  // --

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '95vw',
      height: '95vh', 
      left: leftOffset,
      bottom: 0,
      position: 'absolute',
      backgroundColor: colors?.interface,
      transition: 'left 0.3s',
      overflow: 'hidden'
    }}>

        { section === 'selectPatient' && <SelectPatient />}

        { section === 'dashboard' && <Dashboard />}

        { section === 'noteViewer' && <NoteViewer />}

        { section === 'fileViewer' && <FileViewer />}

        { section === 'imageViewer' && <ImageViewer />}

        {isPopupOpened &&
          <Popup>
            {action === 'quitConsultation' && <ExitPopup />}
            {action === 'saveConsultation' && <ConfirmationPopup />}
            {action === 'uploadFile' && <LoadingPopup />}
            {action === 'uploadImage' && <ImageLoadingPopup />}
          </Popup>}

    </Box>
  );
};

export default Consultation;