import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// Components 

import Box from '@mui/material/Box';
import MicNoneIcon from '@mui/icons-material/MicNone';
import { editConsultationNote } from '../../../../routes/doctor/consultation';

// -- 

const Microphone: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const colors = useAppSelector(state => state.theme.colors); 
    const consultationNoteId = useAppSelector(state => state.noteMicrophone.consultationNoteId); 
    const language = useAppSelector(state => state.noteMicrophone.language); 
    const securityPin = useAppSelector(state => state.noteMicrophone.securityPin); 

    // -- 

    const handleMicrophone = () => { 

        if (listening) { 

            SpeechRecognition.stopListening(); 

        } else { 

            SpeechRecognition.startListening({ language: language });

        }; 

    }; 

    // -- 

    const handleNewText = async () => { 

        const content = { 
            consultationNoteId, 
            newDescription: transcript,
            mode: 'speaking',
            securityPin: securityPin, 
        }; 

        await editConsultationNote(content) as any; 

    }; 

    // --

    useEffect(() => { 

        if (!listening && transcript) { 

            handleNewText(); 

        }; 

    },[listening]); 

    // -- 

    return (

      <Box
        onClick={handleMicrophone}
        sx={{ 
            display: 'flex',
            flexDirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            borderRadius: '50%', 
            width: 100, 
            height: 100,
            backgroundColor: listening ? colors?.microphoneEnabled : colors?.microphoneDisabled,
        }}
      >

        <MicNoneIcon sx={{ fontSize: 50, color: colors?.microphoneIcon }} /> 

      </Box>

  );

};

export default Microphone;