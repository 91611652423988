import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components

import FolderIcon from '@mui/icons-material/Folder';
import { deleteConsultationNote } from '../../../../../routes/doctor/consultation';
import { setSection, setSelectedConsultationNote } from '../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';

type Props = {
    children?: React.ReactNode; 
    action: string;
};


const ActionRow: React.FC<Props> = ({ children, action }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const selectedConsultationNote = useAppSelector((state) => state.consultation.selectedConsultationNote) as any; 

    // -- 

    const handleAction = async () => { 

        if (action === 'deleteConsultationNote') { 

            const res = await deleteConsultationNote(selectedConsultationNote._id); 

            if (res?.data.status === 200) { 

                dispatch(setSelectedConsultationNote(null)); 

                dispatch(setNotificationMessage(t('noteDeleted'))); 

                dispatch(setSection('dashboard')); 

            }; 

        }; 

        // -- 

    }; 

    return (

        <Box 
            onClick={handleAction}
            sx={{
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 40,
            marginLeft: 4, 
            overflow: 'hidden',
            color: colors?.text,
            cursor: 'pointer', 
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: colors?.sectionHover }
        }}>

            {children}

            <Box sx={{ fontSize: 14, marginLeft: 2, overflow: 'hidden', whiteSpace: 'nowrap' }}>{t(action)}</Box>

        </Box>

    );

};

export default ActionRow;