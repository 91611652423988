import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
// Redux 

import { useTranslation } from 'react-i18next';
import { setClosePopup } from '../../../../../../../redux/features/general/popup';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const ImageLoadingPopup: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    return (
        
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            width: '100%',
            height: '100%',
            borderRadius: 5,
            backgroundColor: colors?.container,
            overflowY: 'auto',
            overflowX: 'hidden',
        }}>

            <CircularProgress size={50} />

            <Box>{t('loadingImageUpload')}</Box>

        </Box>
  );
};

export default ImageLoadingPopup;