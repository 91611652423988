import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface PopupState {
    isPopupOpened: boolean,
    action: string, 
}

// ---------------

const initialState: PopupState = {
    isPopupOpened: false,
    action: '', 
};

// ---------------


export const popupSlice = createSlice({

  name: 'popup',

  initialState,

  reducers: {

    setOpenPopup: (state) => {

        state.isPopupOpened = true; 
    },
    setClosePopup: (state) => {

      state.isPopupOpened = false; 
      state.action = ''; 

    },
    setPopupAction: (state, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setResetPopup: (state) => { 
        
      state.isPopupOpened = false; 
      state.action = ''; 

    } 
  },

}); 

// ---------------

export const { setOpenPopup, setClosePopup, setPopupAction, setResetPopup } = popupSlice.actions;


export default popupSlice.reducer;