import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Components 

import Box from '@mui/material/Box';
import CategoriesList from './CategoriesList';
import SubmitButton from '../actions/SubmitButton';
import ExitButton from '../actions/ExitButton';
import Warning from './Warning';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const ProgressColumn: React.FC<Props> = ({ children, isPortraitMode }) => {

  const colors = useAppSelector(state => state.theme.colors);

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-between', 
        width: '23%',
        height: '95%',
        borderRadius: 5,
        backgroundColor: colors?.container,
        overflowY: 'hidden',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
    }}>

      <Box
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'space-between', 
          width: '100%', 
        }}
      >

        <CategoriesList />

      </Box>

      <Box
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'space-between', 
          width: '100%', 
        }}
      >
          <Warning /> 

          <SubmitButton /> 

          <ExitButton />
        
      </Box>

    </Box>
  );
};

export default ProgressColumn;