import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';

const Legend: React.FC = () => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const section = useAppSelector(state => state.savedConsultation.section);  


    return (
        
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '3vh',
            width: '100%',
            fontSize: 12, 
            backgroundColor: colors?.legend,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',        
            color: colors?.text
        }}>

            { section === 'consultations' && 
            
            <>

                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%',
                    marginLeft: '2%',  
                }}>
                    <FolderOpenOutlinedIcon  sx={{ fontSize: 20, color: 'grey', marginLeft: 1 }} /> 
                </div>
                
                <Box sx={{ width: '70%' }}>{t('doctor')}</Box>
                <Box sx={{ width: '10%' }}>{t('dateModified')}</Box>

            </>}

            { section === 'categories' && 
            
            <>

                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%',
                    marginLeft: '2%',  
                }}>
                    <FolderOpenOutlinedIcon  sx={{ fontSize: 20, color: 'grey', marginLeft: 3 }} /> 
                </div>
                
                <Box sx={{ width: '50%' }}>{t('category')}</Box>
                <Box sx={{ width: '20%' }}>{t('items')}</Box>

            </>}

            { section === 'notes' && 

            <>
            
            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                minWidth: '10%',
                marginLeft: '2%',  
            }}>
              <CircleOutlinedIcon sx={{ fontSize: 15, marginLeft: 1 }} />
            </div>
            
            <Box sx={{ width: '70%' }}>{t('name')}</Box>
            <Box sx={{ width: '10%' }}>{t('dateModified')}</Box>

            </>}

            { section === 'files' && 

            <>

                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%',
                    marginLeft: '2%',  
                }}>
                <CircleOutlinedIcon sx={{ fontSize: 15, marginLeft: 1 }} />
                </div>

                <Box sx={{ width: '70%' }}>{t('title')}</Box>
                <Box sx={{ width: '10%' }}>{t('dateModified')}</Box>

            </>}

            { section === 'images' && 

            <>

                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%',
                    marginLeft: '2%',  
                }}>
                <CircleOutlinedIcon sx={{ fontSize: 15, marginLeft: 1 }} />
                </div>

                <Box sx={{ width: '70%' }}>{t('title')}</Box>
                <Box sx={{ width: '10%' }}>{t('dateModified')}</Box>

            </>}

        </Box>
        

    );
};

export default Legend;