import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import { addTextField, setCurrentPageHeight, setCurrentPageWidth, setIsCreatingTextField, setIsRefreshFieldsList, setNewTextFieldPosition, setSelectedTextField, setSelectedTextFieldId, setTextFields } from '../../../redux/features/template/template';
import TextField from './items/TextField';
import { createTemplateTextField, getTemplateTextFields } from '../../../routes/doctor/templates';

const Document: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const templateRef = useRef() as any;

    const imageComponent = document?.getElementById('template-img'); 

    const templateId = useAppSelector(state => state.template.templateId); 
    const currentPdfUrl = useAppSelector(state => state.template.currentPdfUrl); 

    const textFields = useAppSelector(state => state.template.textFields); 
    const isCreatingTextField = useAppSelector(state => state.template.isCreatingTextField); 
    const newTextFieldPosition = useAppSelector(state => state.template.newTextFieldPosition);
    const isRefreshFieldsList = useAppSelector(state => state.template.isRefreshFieldsList);

    const [height, setHeight] = useState(0); 
    const [width, setWidth] = useState(0); 

    const [startX, setStartX] = useState(0); 
    const [startY, setStartY] = useState(0); 

    const [endX, setEndX] = useState(0);
    const [endY, setEndY] = useState(0); 

    const [timeLeft, setTimeLeft] = useState(0); 

    const [isMousePressed, setIsMousePressed] = useState(false); 

    // -- 

    templateRef?.current?.addEventListener("mousedown", (e: any) => {

      if (isCreatingTextField) { 

        setStartX(e.offsetX); 
        setStartY(e.offsetY);
        
        setIsMousePressed(true); 

      }; 

    }, { once: true });

    // --

    templateRef?.current?.addEventListener("mouseup", (e: any) => {

        if (isMousePressed) { 

          setEndX(e.offsetX); 
          setEndY(e.offsetY);

          setTimeLeft(2); 

          setIsMousePressed(false); 

        }; 

    }, { once: true });

    // -- 

    templateRef?.current?.addEventListener("mousemove", (e: any) => {

        if (isMousePressed) { 

          setEndX(e.offsetX); 
          setEndY(e.offsetY);

        }; 

    });

    // -- 

    const handleTextFieldCreation = async (textFieldContent: any) => { 

      const res = await createTemplateTextField(templateId, textFieldContent.x, textFieldContent.y, textFieldContent.width, textFieldContent.height, {}); 

      if (res?.data.status === 200) { 

        dispatch(addTextField(res.data.templateTextField)); 
        dispatch(setSelectedTextField({ textField: res.data.templateTextField, index: textFields.length })); 
        dispatch(setSelectedTextFieldId(res.data.templateTextField._id)); 

      }; 

    }; 

    // -- 

    const handleUnseletion = () => { 

      dispatch(setSelectedTextField(null)); 

    }; 

    // --

    useEffect(()=>{

      let myInterval = setInterval(() => {

          if (timeLeft > 0) {

              setTimeLeft(timeLeft - 1);

          }; 

          if (timeLeft === 1) { 

            if (isCreatingTextField) { 

              const content = { 
                x: startX,
                y: startY, 
                width: (endX - startX) > 40 ? (endX - startX) : 40, 
                height: (endY - startY) > 23 ? (endY - startY) : 23, 
              };

              handleTextFieldCreation(content); 
      
              dispatch(setIsCreatingTextField(false)); 
              setIsMousePressed(false); 

            }; 

          }; 

          if (timeLeft === 0) {

              if (timeLeft === 0) {
                  clearInterval(myInterval)
              };
          }; 

      }, 300); 

      return ()=> {
          clearInterval(myInterval);
      };

    },[timeLeft]);

    // -- 

    useEffect(() => { 

      if (imageComponent) { 

        const realHeight = document?.getElementById('template-img')?.offsetHeight as any; 
        const realWidth = document?.getElementById('template-img')?.offsetWidth as any; 
  
        setHeight(realHeight); 
        setWidth(realWidth); 

      }; 

    },[imageComponent]); 

    // -- 

    return (

      <Box
        id='template'
        ref={templateRef}
        sx={{ 
          width: '80vw', 
          height: '90vh',
          borderRadius: 3,
          position: 'absolute',
          right: 0,
          bottom: 0, 
          overflow: 'scroll',
          scrollbarWidth: 'none',  
        }}
      >

      <Box
        id='template-pdf'
        sx={{ 
            borderWidth: 'none', 
            border: 0,
            borderRadius: 3, 
            cursor: isCreatingTextField ? 'crosshair' : 'default',
            backgroundColor: 'rgb(255, 255, 255)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            height: height, 
            width: width
        }}
      >

        { currentPdfUrl && 
        <Box
          id='template-img'
          component="img"
          onClick={handleUnseletion}
          sx={{
            height: 'auto',
            width: 'auto',
          }}
          alt="Template"
          src={currentPdfUrl}
        />}

        { (isCreatingTextField && isMousePressed) && 
            <div
            style={{ 
                position: 'absolute', 
                left: startX,
                top: startY, 
                width: (endX - startX), 
                height: (endY - startY), 
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor: 'black',
                color: 'black',
            }}
        ></div>}

        { textFields?.length > 0 && 
            textFields?.map((item, index) => (
            <TextField 
              key={index} 
              textFieldId={item._id}
              x={item.x}
              y={item.y}
              width={item.width}
              height={item.height}
              description={item.description}
              attribute={item.attribute}
              style={item.style}
              textField={item}
              index={index}
              isRemoved={item.isRemoved}
            />
        ))}

      </Box>

      </Box>

  );

};

export default Document;