import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Legend: React.FC = () => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);  

    return (
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '3vh',
            width: '100%',
            fontSize: 12, 
            backgroundColor: colors?.legend,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',        
            color: colors?.text
        }}>
            
            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                minWidth: '10%',
                marginLeft: '2%',  
            }}>
                { patientId ? 
                <CheckCircleIcon sx={{ fontSize: 15, color: colors?.legendCheckIcon, marginLeft: 1 }} /> 
                : <CircleOutlinedIcon sx={{ fontSize: 15, marginLeft: 1 }} />}
            </div>
            
            <Box sx={{ width: '30%' }}>{t('name')}</Box>
            <Box sx={{ width: '40%' }}>{t('patientId')}</Box>
            <Box sx={{ width: '10%' }}>{t('dateModified')}</Box>

        </Box>
    );
};

export default Legend;