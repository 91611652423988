import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 
 
import Action from '../../header/Action';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const ImageDropdown: React.FC = () => {

    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    return ( 

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 200,
                height: 'auto',
                top: 50, 
                right: 20, 
                position: 'absolute',
                backgroundColor: colors?.fileExplorerDropdown,
                borderRadius: 4,
                zIndex: 10000, 
                transition: 'width 0.3s',
            }}
        >

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    width: '100%', 
                    cursor: 'pointer',
                }}
            >

                <Action title={'viewImage'} action={'viewImage'} type='dropdown'>
                    <RemoveRedEyeOutlinedIcon /> 
                </Action>

                <Action title={'removeImage'} action={'removeImage'} type='dropdown'>
                    <DeleteOutlineIcon /> 
                </Action>

            </Box>

        </Box>

    )

}; 

export default ImageDropdown; 