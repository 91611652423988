import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { setFillingRgbColor } from 'pdf-lib';

// Redux 

import { addRemovedTextField, setCurrentTextField, setCurrentTextFieldMove, setCurrentTextFieldName, setCurrentTextFieldPosition, setIsAttributesDropdownOpened, setIsRefreshFieldsList, setPendingTextFields, setRemovedTextFields, setSection, setSelectedTextField, setSelectedTextFieldId, setTextFields, setUpdatePdf, updateTextFields } from '../../../../../redux/features/template/template';

// Routes 

import { deleteTemplateTextField, getTemplateTextField, updateTemplateTextField } from '../../../../../routes/doctor/templates';

// Components 

import Box from '@mui/material/Box';
import ArticleIcon from '@mui/icons-material/Article';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TitleIcon from '@mui/icons-material/Title';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import WcIcon from '@mui/icons-material/Wc';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddHomeIcon from '@mui/icons-material/AddHome';
import EmailIcon from '@mui/icons-material/Email';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TollIcon from '@mui/icons-material/Toll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NotesIcon from '@mui/icons-material/Notes';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props { 
    key: number, 
    textFieldId: string, 
    x: number,
    y: number,
    width: number,
    height: number, 
    description?: string,
    attribute?: string,
    style: string,
    textField: any,
    index: number, 
    isRemoved?: boolean, 
  }; 

const FormRow: React.FC<Props> = ({textFieldId, x, y, width, height, description, attribute, style, textField, index, isRemoved }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const textFields = useAppSelector(state => state.template.textFields);
    const selectedTextFieldId =  useAppSelector(state => state.template.selectedTextFieldId);
    const selectedTextFieldIndex = useAppSelector(state => state.template.selectedTextFieldIndex);
    const isAttributesDropdownOpened = useAppSelector(state => state.template.isAttributesDropdownOpened);

    // -- 

    const handleSelection = async () => { 

        if (selectedTextFieldId != textFieldId) { 
    
            dispatch(setSelectedTextField({ textField, index })); 

        };

    }; 

    // -- 

    const handleClose = () => { 
    
        dispatch(setSelectedTextField(null)); 

    };

    // -- 

    const handleTextFieldRemoval = async () => { 

        const res = await deleteTemplateTextField(textFieldId) as any; 

        if (res?.data.status === 200) { 

            const updatedTextFieldsList = textFields?.filter((textField) => textField._id != textFieldId) as any; 

            dispatch(setTextFields(updatedTextFieldsList)); 

        }; 

    }; 

    // -- 

    const removeAttribute = async () => { 

        const content = { 
            textFieldId: selectedTextFieldId,
            attribute: 'none', 

        }; 

        const res = await updateTemplateTextField(content) as any; 

        if (res.data.status == 200) {

            dispatch(updateTextFields({ 
                selectedTextFieldId: selectedTextFieldId,
                updatedTextField: res.data.templateTextField,
                index: selectedTextFieldIndex
            })); 

            dispatch(setSelectedTextField({ textField: res.data.templateTextField, index: selectedTextFieldIndex }));

        }; 
        
    };

    // -- 

    const handleAttributes = () => { 

        dispatch(setSection('forms'));
        dispatch(setIsAttributesDropdownOpened(true)); 

    };

    // -- 

    return (

        <>

      { !isRemoved && 
      
      <Box 
        onClick={handleSelection}
        sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '95%',
          marginLeft: '2.5%', 
          borderRadius: 3,
          marginTop: 1,  
          paddingTop: 1, 
          paddingBottom: 1, 
          height: 'auto', 
          cursor: (selectedTextFieldId == textFieldId) ? 'default' : 'pointer', 
          fontSize: 12, 
          backgroundColor: (selectedTextFieldId == textFieldId) ? colors?.selectedFormRow : colors?.formRow,
          '&:hover': { backgroundColor: (selectedTextFieldId == textFieldId) ? colors?.selectedFormRow :  colors?.formRowHover},
          transition: 'height 0.3s',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between', 
                paddingLeft: 2, 
                height: 30, 
                width: '100%',
            }}
        
        >
            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                }}
            >
                <TitleIcon sx={{ color: colors?.formRowTitle }} /> 
                <Box sx={{ marginLeft: 2, marginRight: 4 }}></Box>
            </Box>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                }}
            >
                { attribute === 'patientId' && (selectedTextFieldId != textFieldId)  && <TollIcon sx={{ fontSize: 18, color: colors?.patientId }}/> }
                { attribute === 'firstName' && (selectedTextFieldId != textFieldId)  && <AccountCircleIcon sx={{ fontSize: 18, color: colors?.firstName }}/> }
                { attribute === 'lastName' && (selectedTextFieldId != textFieldId)  && <AccountBoxIcon sx={{ fontSize: 18, color: colors?.lastName}}/>}
                { attribute === 'sex' && (selectedTextFieldId != textFieldId)  && <WcIcon sx={{ fontSize: 18, color: colors?.gender }}/>}
                { attribute === 'dateOfBirth' && (selectedTextFieldId != textFieldId)  && <EventNoteIcon sx={{ fontSize: 18, color: colors?.dateOfBirth }}/>}
                { attribute === 'healthCardNumber' && (selectedTextFieldId != textFieldId)  && <AccountCircleIcon sx={{ fontSize: 18, color: colors?.healthCardNumber }}/> }
                { attribute === 'phone' && (selectedTextFieldId != textFieldId)  && <LocalPhoneIcon sx={{ fontSize: 18, color: colors?.phone }}/>}
                { attribute === 'address' && (selectedTextFieldId != textFieldId)  && <AddHomeIcon sx={{ fontSize: 18, color: colors?.address }}/>}
                { attribute === 'email' && (selectedTextFieldId != textFieldId)  && <EmailIcon sx={{ fontSize: 18, color: colors?.email }}/>}
                { attribute === 'emergencyContactName' && (selectedTextFieldId != textFieldId)  && <AccountCircleIcon sx={{ fontSize: 18, color: colors?.emergencyContactName }}/>}
                { attribute === 'emergencyContactRelation' && (selectedTextFieldId != textFieldId)  && <AccountBoxIcon sx={{ fontSize: 18, color: colors?.emergencyContactRelation }}/>}
                { attribute === 'emergencyContactPhone' && (selectedTextFieldId != textFieldId)  && <AddHomeIcon sx={{ fontSize: 18, color: colors?.emergencyContactPhone }}/>}

                { (!attribute && (selectedTextFieldId != textFieldId)) && <NotesIcon sx={{ fontSize: 18, color: 'rgb(180, 180, 180)' }} />}

                { (selectedTextFieldId == textFieldId) ?

                    <KeyboardArrowUpIcon 
                        onClick={handleClose}
                        sx={{ 
                            color: (selectedTextFieldId == textFieldId) ? colors?.text : colors?.formRowArrow, 
                            width: 30, 
                            height: 30, 
                            fontSize: 25,
                            marginLeft: 1, 
                            cursor: 'pointer', 
                            borderRadius: '50%', 
                            '&:hover': { color: colors?.text }
                        }} />:
                    <KeyboardArrowDownIcon 
                        sx={{ 
                            color: (selectedTextFieldId == textFieldId) ? colors?.text : colors?.formRowArrow, 
                            width: 30, 
                            height: 30, 
                            marginLeft: 1, 
                            fontSize: 25,
                            borderRadius: '50%', 
                            '&:hover': { color: colors?.text }
                    }} /> }


            </Box>

        </Box>

        { (attribute && (selectedTextFieldId == textFieldId) ) && 
        
        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between', 
                paddingLeft: 2, 
                height: 30, 
                marginTop: 1,
                width: '100%',
            }}
        >

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                }}
            >

                { attribute === 'patientId' && <TollIcon sx={{ fontSize: 18, color: colors?.patientId }}/> }
                { attribute === 'firstName' && <AccountCircleIcon sx={{ fontSize: 18, color: colors?.firstName }}/> }
                { attribute === 'lastName' && <AccountBoxIcon sx={{ fontSize: 18, color: colors?.lastName}}/>}
                { attribute === 'sex' && <WcIcon sx={{ fontSize: 18, color: colors?.gender }}/>}
                { attribute === 'dateOfBirth' && <EventNoteIcon sx={{ fontSize: 18, color: colors?.dateOfBirth }}/>}
                { attribute === 'healthCardNumber' && <AccountCircleIcon sx={{ fontSize: 18, color: colors?.healthCardNumber }}/> }
                { attribute === 'phone' && <LocalPhoneIcon sx={{ fontSize: 18, color: colors?.phone }}/>}
                { attribute === 'address' && <AddHomeIcon sx={{ fontSize: 18, color: colors?.address }}/>}
                { attribute === 'email' && <EmailIcon sx={{ fontSize: 18, color: colors?.email }}/>}
                { attribute === 'emergencyContactName' && <AccountCircleIcon sx={{ fontSize: 18, color: colors?.emergencyContactName }}/>}
                { attribute === 'emergencyContactRelation' && <AccountBoxIcon sx={{ fontSize: 18, color: colors?.emergencyContactRelation }}/>}
                { attribute === 'emergencyContactPhone' && <AddHomeIcon sx={{ fontSize: 18, color: colors?.emergencyContactPhone }}/>}

                <Box sx={{ marginLeft: 2 }}>{t(attribute)}</Box>

            </Box>

            <HighlightOffOutlinedIcon 
                onClick={removeAttribute}
                sx={{ 
                    color: 'rgb(150, 150, 150)', 
                    width: 30, 
                    fontSize: 25, 
                    cursor: 'pointer',
                    '&:hover': { color: colors?.deleteIcon }
            }} />

        </Box>} 


        { (!attribute && (selectedTextFieldId == textFieldId) ) && 
        
        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between', 
                paddingLeft: 2, 
                height: 30, 
                marginTop: 1,
                width: '100%',
            }}
        >

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                }}
            >

                <NotesIcon sx={{ fontSize: 18, color: 'rgb(180, 180, 180)' }} />
                
                <Box sx={{ marginLeft: 2 }} >{t('addAttribute')}</Box>

            </Box>

            <AddCircleOutlineIcon 
                onClick={handleAttributes}
                sx={{ 
                    color: 'rgb(45, 207, 196)', 
                    width: 30, 
                    fontSize: 25, 
                    cursor: 'pointer',
                    '&:hover': { color: colors?.green }
            }} />

        </Box>} 

      </Box>}

      </>

  );

};

export default FormRow;