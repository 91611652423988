import React, { useState, useEffect, useRef } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import { serveConsultationImage, serveConsultationNote } from '../../../../../routes/doctor/consultation';
import { serveFile } from '../../../../../routes/doctor/files';
import Actions from './Actions';

const ImageViewer: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const { t } = useTranslation(); 

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');
    const leftOffset = isSmallScreen ? '60px' : '5vw';

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const colors = useAppSelector(state => state.theme.colors);

    const selectedImage = useAppSelector(state => state.consultation.selectedImage) as any; 

    const [fileUrl, setFileUrl] = useState(''); 

    // -- 

    const handleImage = async () => { 

        const res = await serveFile(selectedImage._id, 'image') as any; 
        
        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
    
        setFileUrl(URL.createObjectURL(fileBlob)); 

    }; 

    // -- 

    useEffect(() => { 

        if (selectedImage) { 

            handleImage(); 

        }; 

    },[selectedImage]); 

    // --

    return (

        <Box 
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: '100%',
                height: '100%', 
        }}>

            <Actions /> 

            <Box 
            
                style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly',
                    width: '80%',
                    height: '95%', 
                    borderRadius: 10,
                    backgroundColor: colors?.container,
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    padding: 2, 
                    overflow: 'scroll'
                    
            }}>

                {fileUrl && 
                    <Box
                        id='image-viewer'
                        component="img"
                        sx={{
                            height: 'auto',
                            width: 'auto',
                        }}
                        alt="Consultation Image"
                        src={fileUrl}
                    />}

            </Box>

        </Box>
  );
};

export default ImageViewer;