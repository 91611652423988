import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components

import PersonIcon from '@mui/icons-material/Person';

interface Props { 
    id: string; 
    fullName: string; 
    updatedAt: string; 
}; 

const PatientRow: React.FC<Props> = ({ id, fullName, updatedAt }) => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);  

    return (

        <>

            <Box 
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                fontSize: 12, 
                height: 50,
                cursor: 'pointer',
                backgroundColor: patientId == id ? colors?.sectionHover : 'transparent',
                '&:hover': { backgroundColor: colors?.sectionHover }
            }}>
        
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%', 
                    marginLeft: '2%',  
                }}>

                    <PersonIcon sx={{ color: colors?.patientsFolderIcon, fontSize: 20, marginLeft: 1 }} />

                </div>
                
                <div style={{ 
                    width: '30%',
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}>{fullName}</div>

                <div style={{ 
                    width: '40%', 
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden', 
                }}>{id}</div>

                <div style={{ 
                    width: '10%', 
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden', 
                }}>{moment.utc(updatedAt).format('L')}</div>
    
            </Box>

        </>

    );
};

export default PatientRow;