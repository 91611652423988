import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import {default as MuiTextField} from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { setAttributesQueryList, setHightlightedAttribute, setIsSavingEnabled, setIsSecurityPopupOpened, setSearchQuery, setSecurityPin, setSelectedAttribute } from '../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import { editConsultationNote, getConsultation, getConsultationNote } from '../../../routes/doctor/consultation';
import { setMode, setIsAttributesDropdownOpened } from '../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import { setClosePopup, setOpenPopup, setPopupAction } from '../../../redux/features/general/popup';
import { setNotificationMessage } from '../../../redux/features/general/notification';
import AttributesDropdown from './AttributesDrowpdown';
import attributesList from './attributesList';

interface Props { 
    width: number;
    height: number; 
}

const Text: React.FC<Props> = ({ width, height }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const textFieldComponent = document?.getElementById("consultationNote-textField") as any; 

    const colors = useAppSelector(state => state.theme.colors); 
    const mode = useAppSelector(state => state.consultationNote.mode); 
    const consultationNoteId = useAppSelector(state => state.consultationNote.consultationNoteId); 
    const securityPin = useAppSelector(state => state.consultationNote.securityPin); 
    const isSecurityPopupOpened = useAppSelector(state => state.consultationNote.isSecurityPopupOpened); 
    const isAttributesDropdownOpened = useAppSelector(state => state.consultationNote.isAttributesDropdownOpened); 
    const attributesQueryList = useAppSelector(state => state.consultationNote.attributesQueryList); 
    const hightlightedAttribute = useAppSelector(state => state.consultationNote.hightlightedAttribute); 
    const selectedAttribute = useAppSelector(state => state.consultationNote.selectedAttribute); 
    const searchQuery = useAppSelector(state => state.consultationNote.searchQuery); 
    const isPopupOpened = useAppSelector(state => state.popup.isPopupOpened); 
    const action = useAppSelector(state => state.popup.action); 

    const [timeLeft, setTimeLeft] = useState(2); 
    const [text, setText] = useState(''); 

    const [dropdownTop, setDropdownTop] = useState(0); 
    const [dropdownLeft, setDropdownLeft] = useState(0); 

    // -- 

    const getTextUpdate = async () => { 

        const res = await getConsultationNote(consultationNoteId) as any; 

        if (res.data.status === 200) { 

            dispatch(setMode(res.data.consultationNote.mode)); 
            dispatch(setSecurityPin(res.data.consultationNote.securityPin)); 

            if ((res.data.consultationNote.mode === 'speaking') || (res.data.consultationNote.mode === 'autoComplete')) { 

                setText(res.data.consultationNote.description); 

            }; 

            if (res.data.consultationNote.isAccessRequested) { 

                dispatch(setSecurityPin(res.data.consultationNote.securityPin)); 

                if (!isSecurityPopupOpened) { 

                    dispatch(setPopupAction('securityPin')); 
                    dispatch(setOpenPopup()); 

                    dispatch(setIsSecurityPopupOpened(true)); 

                }; 

            } else { 

                if (isPopupOpened && (action === 'securityPin')) { 

                    dispatch(setNotificationMessage(t('deviceConnected'))); 

                    dispatch(setClosePopup()); 

                }; 

            }; 

        }; 

    }; 

    // -- 

    const onTextChange = async (e: any) => { 

        const lastElement = e.target.value.slice(-1);

        if (isAttributesDropdownOpened) { 

            if (lastElement === ' ') { 

                dispatch(setIsAttributesDropdownOpened(false)); 
                dispatch(setSearchQuery('')); 

            }; 

        } else { 

            setText(e.target.value); 

            if (lastElement === ':') { 

                dispatch(setAttributesQueryList(attributesList)); 

                dispatch(setIsAttributesDropdownOpened(true)); 
                dispatch(setHightlightedAttribute(attributesList[0])); 
    
                setDropdownTop(e.target.clientHeight + 40); 
    
            } else { 
    
                dispatch(setIsAttributesDropdownOpened(false)); 
                // setSearchQuery(''); 
    
            }; 

            const content = { 
                consultationNoteId, 
                newDescription: e.target.value,
                overWrite: true,
                mode: 'writing',
                securityPin: securityPin, 
            }; 
    
            await editConsultationNote(content) as any; 

        }; 

    }; 

    // -- 

    const handleTextReset = async () => { 

        setText(''); 

        const content = { 
            consultationNoteId, 
            newDescription: '',
            overWrite: true,
            mode: 'writing',
            securityPin: securityPin, 
        }; 

        await editConsultationNote(content) as any; 

        textFieldComponent.focus(); 

    }; 

    // -- 

    const onKeyChange = (e: any) => { 

        const lastElement = text.slice(-1); 

        if ((lastElement === ':') && (e.key === 'Backspace')) { 

            dispatch(setIsAttributesDropdownOpened(false)); 
            dispatch(setSearchQuery('')); 

            dispatch(setAttributesQueryList(attributesList)); 

        } else { 

            const reg = new RegExp('^[a-zA-Z]+$'); 

            if (((e.key === 'Enter') && (isAttributesDropdownOpened))) { 

                dispatch(setSelectedAttribute(hightlightedAttribute)); 

                dispatch(setIsAttributesDropdownOpened(false)); 
                dispatch(setSearchQuery('')); 

            } else if (e.key === 'ArrowDown') { 

                if (isAttributesDropdownOpened) { 

                    const currentIndex = attributesQueryList.indexOf(hightlightedAttribute); 

                    if ((currentIndex > -1) && (attributesQueryList.length > (currentIndex + 1))) { 

                        dispatch(setHightlightedAttribute(attributesQueryList[currentIndex + 1])); 

                    } else if ((currentIndex > -1) && (currentIndex === (attributesQueryList.length - 1))) { 

                        dispatch(setHightlightedAttribute(attributesQueryList[0])); 

                    }; 

                }; 

            } else if (e.key === 'ArrowUp') { 

                if (isAttributesDropdownOpened) { 

                    const currentIndex = attributesQueryList.indexOf(hightlightedAttribute); 

                    if ((currentIndex > -1) && (currentIndex !== 0)) { 

                        dispatch(setHightlightedAttribute(attributesQueryList[currentIndex - 1])); 

                    } else if ((currentIndex > -1) && (currentIndex === 0)) { 

                        dispatch(setHightlightedAttribute(attributesQueryList[attributesQueryList.length - 1])); 

                    }; 

                }; 

            } else if ((e.key === 'BackspaceShift') || (e.key === 'Shift') || !reg.test(e.key) || (e.key.length > 1)) { 

                return; 

            } else { 

                const updatedSearchQuery = `${searchQuery}${e.key}`; 

                dispatch(setSearchQuery(updatedSearchQuery)); 
    
                const updatedAttributesList = attributesList.filter((attribute) => { 
                    
                    const lowercasedAttribute = attribute.toLocaleLowerCase(); 
                    const lowercasedQuery = updatedSearchQuery.toLocaleLowerCase(); 
                    
                    if (lowercasedAttribute.includes(lowercasedQuery)) { 

                        return attribute; 

                    }; 

                }); 
    
                dispatch(setAttributesQueryList(updatedAttributesList)); 

                if (updatedAttributesList.length > 0) { 

                    dispatch(setHightlightedAttribute(updatedAttributesList[0])); 

                }; 

            }; 

        }; 

    }; 

    // -- 

    const handleAutoComplete = async () => { 

        const content = { 
            consultationNoteId, 
            newDescription: '',
            overWrite: false,
            mode: 'autoComplete',
            securityPin: securityPin, 
            attribute: selectedAttribute
        }; 

        await editConsultationNote(content) as any; 

        dispatch(setIsAttributesDropdownOpened(false)); 
        dispatch(setSearchQuery('')); 

    }; 

    // -- 

    useEffect(() => { 

        if (mode === 'resetText') { 

            handleTextReset(); 

        }; 

    },[mode]); 

    // -- 

    useEffect(() => { 

        if (selectedAttribute) { 

            handleAutoComplete(); 

        }; 

    },[selectedAttribute]);

    // -- 

    useEffect(() => { 

        if (text) { 

            dispatch(setIsSavingEnabled(true)); 

        } else { 

            dispatch(setIsSavingEnabled(false)); 

        }; 

    },[text]); 

    // -- 

    useEffect(()=>{

        let myInterval = setInterval(() => {

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft === 1) { 

                getTextUpdate(); 

                setTimeLeft(2); 

            }; 

            if (timeLeft === 0) {

                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 

        }, 500); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);

    // -- 
    
    return (
      
      <Box 
        sx={{ 
            display: 'flex',
            flexDirection: 'column',
            width: '100%', 
            height: '100%', 
            cursor: 'default',
        }}
        >
        
            <MuiTextField
                id="consultationNote-textField"
                variant="standard"
                value={text}
                autoFocus
                autoComplete='off'
                multiline={true}
                sx={{ 
                    marginTop: 5, 
                    marginLeft: '40px', 
                    height: '860px', 
                    width: '560px',
                }}
                inputProps={{ 
                    style: { 
                        textAlign: 'left',
                        textDecorationLine: 'none',
                        // textDecorationStyle: 'solid', 
                        // textDecorationThickness: style?.textDecoration ? 1 : 0,
                        fontSize: 12, 
                        color: colors?.text,
                        fontWeight: 'normal', 
                        fontStyle: 'normal', 
                    }
                }}
                InputProps={{
                    disableUnderline: true, 
                }}
                onChange={onTextChange}
                onKeyDown={onKeyChange} 
            />

            {isAttributesDropdownOpened && <AttributesDropdown top={dropdownTop} left={dropdownLeft} />}

      </Box>

  );

};

export default Text;