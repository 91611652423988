import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import QrCode from './QrCode';

const Sidebar: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '20vw',
          height: '90vh',
          bottom: 0, 
          left: 0, 
          position: 'absolute' 
      }}>

        <QrCode /> 

      </Box>

  );

};

export default Sidebar;