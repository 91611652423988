import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Redux 

import { setRefreshFileExplorer, selectFile, selectFolder, setMode } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { resetEditFile, setEditedFileTitle } from '../../../../../redux/features/doctor/Dashboard/documents/editFile';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';

// Routes

import { serveFile, updateFile, updateFolder } from '../../../../../routes/doctor/files';

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import Dropdown from '../actions/dropdowns/Dropdown';
import TextField from '@mui/material/TextField';
import { resetEditFolder, setEditedFolderTitle } from '../../../../../redux/features/doctor/Dashboard/documents/editFolder';
import { getDoctorName } from '../../../../../routes/doctor/patients';

type Props = {
    index: any, 
    _id: string,
    title: string,
    path: string, 
    createdAt?: string,
    updatedAt?: string,
    type?: string,
    fileUrl?: string | '', 
    searchQuery?: boolean, 
};


const FolderDataBox: React.FC<Props> = ({ index, _id, title, path, fileUrl, createdAt, updatedAt, type, searchQuery }) => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    const selectedFile = useAppSelector((state) => state.fileExplorer.selectedFile); 
    const selectedFolder = useAppSelector((state) => state.fileExplorer.selectedFolder); 
    const lastInternalFileIndex = useAppSelector((state) => state.fileExplorer.lastInternalFileIndex); 
    const folderData = useAppSelector((state) => state.fileExplorer.folderData); 

    const mode = useAppSelector((state) => state.fileExplorer.mode); 
    const isDropdownOpened = useAppSelector((state) => state.fileExplorer.isDropdownOpened); 

    const editedFileTitle = useAppSelector((state) => state.editFile.title); 
    const editedFile = useAppSelector((state) => state.editFile.editedFile); 

    const [formattedTitle, setFormattedTitle] = useState(''); 
    const [formattedType, setFormattedType] = useState(''); 

    const editedFolderTitle = useAppSelector((state) => state.editFolder.editedFolderTitle); 
    const editedFolder = useAppSelector((state) => state.editFolder.editedFolder); 

    const [titleInput, setTitleInput] = useState(''); 
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');  

    const [editedTitle, setEditedTitle] = useState('');

    const [iconSize, setIconSize] = useState(80); 
    const [iconColor, setIconColor] = useState('transparent'); 
    const [hideSelection, setHideSelection] = useState(false); 

    const [blobFileUrl, setBlobFileUrl] = useState('');
    const [fileExtension, setFileExtension] = useState('');  

    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    // -- 

    const handleFileUpdate = async () => { 

        if (((mode === 'editFile') && (editedFileTitle == selectedFile?.title)) || ((mode === 'editFolder') && (editedFolderTitle == selectedFolder?.title))) { 

            dispatch(selectFile(null)); 
            dispatch(selectFolder(null)); 
            dispatch(resetEditFolder()); 
            dispatch(resetEditFile()); 

        } else { 

            let res = {} as any; 

            if (mode === 'editFile') { 

                res = await updateFile(selectedFile._id, editedFileTitle) as any; 

            } else if (mode === 'editFolder') { 

                res = await updateFolder(selectedFolder._id, editedFolderTitle) as any; 

            }; 

            if (res.data.status === 200) { 

                dispatch(setNotificationMessage((mode === 'editFile') ? t('fileUpdatedOK') : t('folderUpdatedOK'))); 

                dispatch(setRefreshFileExplorer(true)); 
                dispatch(selectFile(null)); 
                dispatch(selectFolder(null)); 
                dispatch(resetEditFolder()); 
                dispatch(resetEditFile()); 

            } else if (res.data.status === 401) { 

                if (res.data.error === 'existing_file') { 

                    setError(true); 
                    setErrorMessage(String(t('existingTitle'))); 

                } else if (res.data.error === 'invalid_title') { 

                    setError(true); 
                    setErrorMessage(String(t('invalidTitle'))); 

                };

            }; 

        }; 

    }; 

    // --

    const handleInput = (key: string) => { 

        if (key == 'Enter') { 

            handleFileUpdate(); 

        } else if (key === 'Escape') { 

            dispatch(resetEditFile());
            dispatch(resetEditFolder());
            dispatch(setMode(''));

        };

    };

    // -- 

    const handleGetDoctorName = async () => { 

        const res = await getDoctorName(title) as any; 
        
        if (res.data.status === 200) { 

            setFormattedTitle(res.data.fullName);

        }; 

    }; 

    const handleFileThumbnail = async () => { 

        const res = await serveFile(_id) as any; 
        
        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
    
        setBlobFileUrl(URL.createObjectURL(fileBlob));
        setFileExtension(fileUrl?.split('.').pop() || 'unknown');

    }; 

    // -- 

    useEffect(() => { 

        if (mode === 'editFile') { 

            setTitleInput(editedFileTitle);

        } else if (mode === 'editFolder') { 

            setTitleInput(editedFolderTitle);

        }; 

    },[mode]); 

    // -- 

    useEffect(() => { 

        if (type) { 

            if ((type === 'file') || (type === 'sharedFile')) { 

                handleFileThumbnail(); 

            }; 

        }; 


    },[_id, type]); 

    // -- 

    useEffect(() => { 

        if (mode === 'editFile') { 

            dispatch(setEditedFileTitle(titleInput)); 

        } else if (mode === 'editFolder') { 

            dispatch(setEditedFolderTitle(titleInput)); 

        }; 

    },[titleInput]); 

    // --

    useEffect(() => { 

        if (title && type) { 

            if (type === 'rootSharedDoctorFolder') { 

                handleGetDoctorName(); 

            } else { 

                setFormattedTitle(title); 

            }; 

        }; 

    },[title]); 

    // -- 

    useEffect(() => {
        
        if (type) { 

            if (hideSelection) { 

                if ((selectedFile?._id == _id) || (selectedFolder?._id == _id)) { 

                    return;
                }; 

                setIconColor(colors?.hiddenBox);

                return; 

            };

            type === 'file' && setFormattedType('File'); 
            type === 'folder' && setFormattedType('Folder'); 
            type === 'sharedFolder' && setFormattedType('External');
            type === 'sharedFile' && setFormattedType('Shared File'); 
            type === 'rootSharedDoctorFolder' && setFormattedType('Folder'); 
            type === 'rootSharedFolder' && setFormattedType('Folder'); 
            type === 'consultations' && setFormattedType('Consultations'); 

            type === 'file' && setIconColor(colors?.file); 
            (((type === 'folder') && !searchQuery)) && setIconColor(colors?.folder); 
            (type === 'rootSharedFolder' && !searchQuery) && setIconColor(colors?.sharedFolder);
            (type === 'sharedFolder' && !searchQuery) && setIconColor(colors?.sharedFolder);
            (type === 'sharedFile' && !searchQuery) && setIconColor(colors?.sharedFolder); 
            (type === 'rootSharedDoctorFolder' && !searchQuery) && setIconColor(colors?.sharedFolder);
            type === 'consultations' && setIconColor(colors?.consultationFolder); 

            (type === 'file' && searchQuery) && setIconColor(colors?.searchQueryFile);
            (type === 'folder' && searchQuery) && setIconColor(colors?.searchQueryFolder);

        };

    },[type, searchQuery, hideSelection]); 

    // -- 

    useEffect(() => { 

        if (mode === 'editFile') { 

            setEditedTitle(editedFileTitle); 

        } else if (mode === 'editFolder') { 

            setEditedTitle(editedFolderTitle); 

        }; 

    },[editedFileTitle, editedFolderTitle]); 

    // -- 

    useEffect(() => { 

        if ((((mode === 'moveFile') || (mode === 'moveFolder')) && (type !== 'folder')) || ((mode === 'attachFileToConsultation') && (type === 'rootSharedFolder')))  { 

            setHideSelection(true); 

        } else { 

            setHideSelection(false);

        };

    },[mode, type]);

    // -- 

    return (

        <>

            <Box 
                sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                width: 150,
                marginLeft: 5,
                marginRight: 5,
                borderRadius: 3,
                marginTop: 1, 
                height: 200, 
                cursor: hideSelection ? 'default' : 'pointer',
                backgroundColor: ((selectedFile?._id === _id) || (selectedFolder?._id === _id)) ? 'rgba(0, 255, 238, 0.1)' : 'transparent',
                '&:hover': { backgroundColor: (((selectedFile?._id === _id) || (selectedFolder?._id === _id)) || hideSelection) ? 'transparent' : 'rgba(77, 75, 89, 0.3)' }
            }}>
        
                { ((type === 'folder' && !path?.startsWith('Patients')) && !searchQuery) && <FolderIcon sx={{ color: iconColor, fontSize: iconSize }} />}
                { ((type === 'folder' && path?.startsWith('Patients')) && !searchQuery) && <FolderIcon sx={{ color: iconColor, fontSize: iconSize }} />}
                { (type === 'rootSharedFolder' && !searchQuery) && <FolderIcon sx={{ color: iconColor, fontSize: iconSize }} />}
                { (type === 'sharedFolder' && !searchQuery) && <FolderIcon sx={{ color: iconColor, fontSize: iconSize }} />}                 
                { (type === 'rootSharedDoctorFolder' && !searchQuery) && <FolderIcon sx={{ color: iconColor, fontSize: iconSize }} />}
                { (type === 'folder' && searchQuery) && <FolderIcon sx={{ color: iconColor, fontSize: iconSize }} />}
                { (type === 'consultations' && !searchQuery) && <FolderIcon sx={{ color: iconColor, fontSize: iconSize }} />}

                { (((type === 'file') || (type === 'sharedFile')) && blobFileUrl && fileExtension && (fileExtension === 'pdf')) &&
                
                    <iframe
                        ref={iframeRef}
                        src={blobFileUrl}
                        style={{ 
                            width: 150, 
                            height: 200,
                            overflow: 'hidden', 
                            borderRadius: 5,
                            border: 0,
                        }}
                        title={title}
                    />
                }

                { (((mode === 'editFile') && selectedFile?._id === _id) || ((mode === 'editFolder') && selectedFolder?._id === _id)) ? 
                
                <TextField 
                    sx={{ 
                        width: '95%',
                        color: colors?.text, 
                        textAlign: 'center',
                        whiteSpace: 'wrap',
                        fontSize: 12, 
                    }} 
                    onChange={(e) => setTitleInput(e.target.value)}
                    id="standard-basic" 
                    value={titleInput}
                    variant="standard" 
                    autoFocus={true}
                    error={error}
                    helperText={errorMessage}
                    onKeyDown={(e) => handleInput(e.key)}
                />: 
                <div style={{ 
                    width: '95%',
                    color: (hideSelection && (selectedFile?._id != _id && selectedFolder?._id != _id)) ? colors?.hiddenBox : colors?.text, 
                    textAlign: 'center',
                    overflow: 'hidden',
                    fontSize: 12, 
                    whiteSpace: 'wrap'
                }}>{formattedTitle}</div>}
    
            </Box>

            { (isDropdownOpened && ((selectedFile?._id === _id) || (selectedFolder?._id === _id))) && <Dropdown /> }

        </>

    );

};

export default FolderDataBox;