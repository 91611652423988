import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import { setIsOptionsListOpened } from '../../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';
import { editConsultationNote } from '../../../../routes/doctor/consultation';
import { setNotificationMessage } from '../../../../redux/features/general/notification';

const OptionsList: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const consultationNoteId = useAppSelector(state => state.noteMicrophone.consultationNoteId); 
    const securityPin = useAppSelector(state => state.noteMicrophone.securityPin); 

    // -- 

    const handleQuit = () => { 

        dispatch(setIsOptionsListOpened(false)); 

    }; 

    // -- 

    const handleSaveConsulation = async () => { 

        const content = { 
            consultationNoteId: consultationNoteId, 
            mode: 'saveConsultationNote', 
            securityPin: securityPin, 
        }; 

        await editConsultationNote(content) as any; 

        handleQuit(); 

    }; 

    // -- 

    const handleTextReset = async () => { 

        const content = { 
            consultationNoteId: consultationNoteId, 
            mode: 'resetText', 
            securityPin: securityPin, 
        }; 

        const res = await editConsultationNote(content) as any; 

        if (res.data.status === 200) { 

            handleQuit(); 

        }; 

    }; 

    // -- 

    return (

      <Box 
        sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            height: '100vh',
            width: '100vw', 
            bottom: 0, 
            position: 'absolute', 
            overflow: 'hidden',  
            backgroundColor: colors?.micOptionsListShadow, 
      }}>

        <Box
            onClick={handleQuit}
            sx={{ 
                width: '100%', 
                height: '100%', 
            }}
        >

        </Box>


        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%', 
                height: 'auto', 
                zIndex: 100, 
                bottom: 0, 
                position: 'absolute', 
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15, 
                paddingTop: 3, 
                paddingBottom: 3, 
                backgroundColor: colors?.micOptionsListBackground
        }}>

            <Button 
                onClick={handleSaveConsulation}
                sx={{ 
                    minWidth: 200, 
                    textTransform: 'none',
                    backgroundColor: colors?.createPatient 
                }} 
                variant="contained">{t('saveConsulationNote')}
            </Button>

            <Button 
                onClick={handleTextReset}
                sx={{ 
                    minWidth: 200, 
                    marginTop: 3, 
                    textTransform: 'none',
                    backgroundColor: colors?.orange 
                }} 
                variant="contained">{t('resetText')}
            </Button>

            <Button 
                onClick={handleQuit}
                sx={{ 
                    minWidth: 200, 
                    marginTop: 3, 
                    textTransform: 'none',
                    backgroundColor: colors?.cancelButtonBackground
                }} 
                variant="contained">{t('back')}
            </Button>

        </Box>

      </Box>

  );

};

export default OptionsList;