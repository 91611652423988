import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setMode } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Components 

import Box from '@mui/material/Box';

// Icons 

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { setSelectedConsultationNote } from '../../../../../../redux/features/doctor/Dashboard/consultation/savedConsultation';
import ImageDetails from './ImageDetails';


const ImageViewerOptions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    const handleQuit = () => { 

        dispatch(setMode('consultations'));

        dispatch(setSelectedConsultationNote(null)); 

    }; 

    // -- 
    
    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '100%',
            marginTop: 1,
        }}>

            <Box
                onClick={handleQuit} 
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: 40, 
                    marginBottom: 2,
                    cursor: 'pointer', 
                    '&:hover': { backgroundColor: colors?.sectionHover}
                }}
            >
                <ArrowCircleLeftOutlinedIcon sx={{ marginLeft: 2 }} /> 
                <Box sx={{ marginLeft: 1, fontSize: 12 }}>{t('quitView')}</Box>

            </Box>

            <ImageDetails /> 

        </Box>

    );

};

export default ImageViewerOptions;