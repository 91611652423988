import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux 

import { useAppSelector } from '../../../redux/hooks';

// Components

import CreatePatient from './patients/createPatient/CreatePatient';
import Patient from './patients/patient/Patient';
import Popup from '../../general/Popup';
import WarningPopup from './patients/patient/options/WarningPopup';
import ExitProcess from './patients/createPatient/actions/ExitProcess';

const PatientsInterface: React.FC = () => {
  const theme = useTheme();

  const isPortraitMode = useMediaQuery('(orientation: portrait)');
  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '6%' : '5vw';

  const colors = useAppSelector((state) => state.theme.colors); 
  const section = useAppSelector((state) => state.patient.section); 

  const isPopupOpened = useAppSelector(state => state.popup.isPopupOpened); 
  const action = useAppSelector(state => state.popup.action); 

  return (
    <Box
      sx={{
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '95vw',
        height: '95vh', 
        left: leftOffset,
        bottom: 0,
        position: 'absolute',
        backgroundColor: colors?.interface,
        transition: 'left 0.3s',
      }}
    >
      { section === 'patient' && <Patient />}
      { section === 'createPatient' && <CreatePatient />}

      {isPopupOpened && 
        <Popup>
          { action === 'removePatient' && <WarningPopup />} 
          { action === 'cancelPatientCreation' && <ExitProcess />} 
        </Popup>}
      
    </Box>
  );
};

export default PatientsInterface;
