import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// -- 

const getBlobHeadersConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
    }; 

    return config as any; 

}; 

// -- 

export const getConsultation = async (consultationId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving a consultation:', error);
    }
};

// -- 

export const getConsultations = async (patientId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/patient/${patientId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving a list of consultations:', error);
    }
};

// -- 

export const createConsultation = async (patientId: string) => {

    try {

        const content = { 
            patientId: patientId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error creating a consultation:', error);
    }
};

// -- 

export const saveConsultation = async (consultationId: string) => {

    try {

        const content = { 
            consultationId: consultationId
        }; 

        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/consultation`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error saving a consultation:', error);
    }
};

// -- 

export const deleteConsultation = async (consultationId: string) => {

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/consultation/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting a consultation:', error);
    }; 
};

// --- 

// ---

// --- 

// --- Consultation Notes 

// -- 

// -- 

// -- 

// -- 

// -- 

export const getConsultationNote = async (consultationNoteId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/${consultationNoteId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving a consultation note.', error);
    }; 

}; 

export const getConsultationNotes = async (consultationId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/notes/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving the consultation notes.', error);
    }; 

}; 

export const createConsultationNote = async (patientId: string, consultationId: string) => {

    try {

        const content = { 
            patientId: patientId,
            consultationId: consultationId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/note`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error creating a consultation note:', error);
    }
};

// -- 

interface EditConsultationNoteProps { 
    consultationNoteId: string; 
    mode: string; 
    newDescription?: string; 
    overWrite?: boolean; 
    securityPin: string; 
    attribute?: string; 
}; 

export const editConsultationNote = async (content: EditConsultationNoteProps) => {

    try {

        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/user/consultation/note/${content.consultationNoteId}`, content);

        return res; 
        
    } catch (error) {
        console.error('Error editing a consultation note:', error);
    }
};

// -- 

export const serveConsultationNote = async (consultationNoteId?: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/serve/${consultationNoteId}`, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error serving a consultation note.', error);
    }; 

}; 


// -- 

export const saveConsultationNote = async (formData: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/save`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error saving a consultation note.', error);
    }; 

}; 

// -- 

export const deleteConsultationNote = async (consultationNoteId: string) => {

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/consultation/note/${consultationNoteId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting a consultation note:', error);
    }; 
};

// --- 

// ---

// --- 

// --- Consultation Files 

// -- 

// -- 

// -- 

// -- 

// -- 

export const getAttachedFiles = async (consultationId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/files/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving the consultation files.', error);
    }; 

};  

// -- 

export const attachFileToConsultation = async (formData: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/file/attach`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error attaching a file to the consultation.', error);
    }; 

}; 

// -- 

export const deleteAttachedFile = async (attachedFileId: string) => { 

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/consultation/file/${attachedFileId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting an attached file.', error);
    }; 

}; 


// ---

// --- 

// --- Consultation Images 

// -- 

// -- 

// -- 

export const serveConsultationImage = async (imageId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/image/${imageId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error serving an image.', error);
    }; 

};  

// -- 

export const getConsultationImages = async (consultationId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultation/images/${consultationId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error retrieving the consultation images.', error);
    }; 

};  

// -- 

export const uploadImageToConsultation = async (formData: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/consultation/image`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error attaching an images to the consultation.', error);
    }; 

}; 

// -- 

export const deleteConsultationImage = async (imageId: string) => { 

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/consultation/image/${imageId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting an attached image.', error);
    }; 

}; 
