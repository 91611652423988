import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { Box } from '@mui/material';
import { useTheme } from '../themeContext';
import { isMobile } from 'react-device-detect';

// -- Components 

import Notification from '../components/general/Notification';

// Utils 

import clearLocalStorage from '../util/storage/clearLocalStorage';
import clearCookies from '../util/storage/clearCookies';
import MobileLogin from '../components/login/mobile/MobileLogin';
import Container from '../components/login/Container';

const Login: React.FC = () => {

  const { isLightMode, toggleTheme } = useTheme();

  const colors = useAppSelector(state => state.theme.colors);

  useEffect(() => { 

    if (isLightMode) {

      toggleTheme(); 

    }; 

  },[isLightMode]); 

  // -- 

  useEffect(() => { 

    clearLocalStorage();
    clearCookies(); 

  },[]); 

  // -- 

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'space-evenly', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: colors?.highlightedText
      }}
    >
    
      { isMobile ? <MobileLogin /> : <Container />}

      <Notification />

    </Box>
  );
};

export default Login;